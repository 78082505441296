import _ from 'lodash'
import * as ActionTypes from '../utils/actionTypes'
import { eventType } from '../utils/const'
import { sortCompareFuncByDate } from '../utils/functions'

const initialState = {
  events: [{
    ...eventType
  }],
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {

    case ActionTypes.CLEAN_CASE_INFO:
      newState.events = [{
        ...eventType
      }]
      return newState
    
    case ActionTypes.UPDATE_EVENTS_INFO:
      newState.events = action.payload.sort((a, b) => sortCompareFuncByDate(b, a, 'date'))
      return newState

    case ActionTypes.ADD_EVENTS_SUCCESS:
      newState.events = action.payload.sort((a, b) => sortCompareFuncByDate(b, a, 'date'))
      return newState

    case ActionTypes.UPDATE_EVENTS_SUCCESS:
      newState.events = action.payload.data.sort((a, b) => sortCompareFuncByDate(b, a, 'date'))
      return newState

    case ActionTypes.GET_EVENTS_SUCCESS: {
      const events = action.payload.sort((a, b) => sortCompareFuncByDate(b, a, 'date'))
      if (events.length > 0) {
        newState.events = events
      } else {
        newState.events = [{
          ...eventType
        }]
      }
      return newState
    }

    default:
      return state
  }
}

export default reducer
