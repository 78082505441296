import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, TextField, Typography, Button, IconButton } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/DeleteOutline'
import { cloneDeep } from 'lodash'

import DatePicker from '../../common/datepicker'
// import DefaultCurrencyField from '../../common/defaultCurrencyField'
import CustomCurrencyField from '../../common/customCurrencyField'
import {
  updateLedgerInfo,
  updateLedgerAmount,
  getLedger,
  addLedger,
  updateLedger
} from '../../../actions/data'
import { ledgerType } from '../../../utils/const'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingBottom: spacing(1)
  },
  paper: {
    padding: spacing(2),
    marginBottom: spacing(3)
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: spacing(1),
    marginRight: spacing(1)
  },
  field: {
    flex: 1,
    maxWidth: 300,
    margin: spacing(1)
  },
  btn: {
    width: spacing(20),
    margin: spacing(1)
  }
}))

const Ledger = ({
  selectedCaseId,
  caseInfo,
  ledgerInfo,
  ledgerAmount,
  updateLedgerInfo,
  updateLedgerAmount,
  getLedger,
  addLedger,
  updateLedger
}) => {
  const classes = useStyles()
  useEffect(() => {
    if (selectedCaseId) {
      getLedger(selectedCaseId)
    }
  }, [])

  const onChange = (id, type, value) => {
    const ldrs = cloneDeep(ledgerInfo)
    ldrs.forEach((p, index) => {
      if (index === id) {
        p[type] = value
      }
    })
    updateLedgerInfo(ldrs)
  }

  const addItem = () => {
    const ldrs = cloneDeep(ledgerInfo)
    ldrs.push(ledgerType)
    updateLedgerInfo(ldrs)
  }

  const removeItem = (index) => {
    if (ledgerInfo.length > 1) {
      const ldrs = cloneDeep(ledgerInfo)
      ldrs.splice(index, 1)
      updateLedgerInfo(ldrs)
    }
  }

  const onChangeLedgerAmount = (type, value) => {
    updateLedgerAmount({...ledgerAmount, [type]: value})
  }

  const onSave = () => {
    if (selectedCaseId) {
      updateLedger(selectedCaseId, { ledgerInfo, ledgerAmount })
    } else {
      addLedger(caseInfo.caseId, { ledgerInfo, ledgerAmount })
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Judgement Recovery Ledger</Typography>
        {
          ledgerInfo.map((e, index) => (
            <Box display="flex" alignItems="flex-end" key={index}>
              <DatePicker className={classes.field} label="Start Date" value={e.startDate} onChange={(value) => onChange(index, 'startDate', value)} />
              <DatePicker className={classes.field} label="End Date" value={e.endDate} onChange={(value) => onChange(index, 'endDate', value)} />
              <TextField className={classes.field} label="Days" type="number" value={e.days} onChange={(e) => onChange(index, 'days', e.target.value)} />
              <TextField className={classes.field} label="Description" value={e.description} onChange={(e) => onChange(index, 'description', e.target.value)} />
              <CustomCurrencyField
                className={classes.field}
                label="Recovery"
                value={e.recovery}
                onChange={(e, value) => onChange(index, 'recovery', value)}
              />
              <CustomCurrencyField
                className={classes.field}
                label="Principal"
                value={e.principal}
                onChange={(e, value) => onChange(index, 'principal', value)}
              />
              <CustomCurrencyField
                className={classes.field}
                label="Interest"
                value={e.interest}
                onChange={(e, value) => onChange(index, 'interest', value)}
              />
              <IconButton onClick={() => removeItem(index)}><RemoveIcon /></IconButton>
            </Box>
          ))
        }
        <Button className={classes.btn} color="primary" variant="contained" onClick={() => addItem()}>Add</Button>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Amount Recovered</Typography>
        <Box>
          <CustomCurrencyField 
            className={classes.field}
            label="Total"
            value={ledgerAmount.recoveredTotal}
            onChange={(e, value) => onChangeLedgerAmount('recoveredTotal', value)}
          />
          <CustomCurrencyField 
            className={classes.field}
            label="Principal"
            value={ledgerAmount.recoveredPrincipal}
            onChange={(e, value) => onChangeLedgerAmount('recoveredPrincipal', value)}
          />
          <CustomCurrencyField 
            className={classes.field}
            label="Interest"
            value={ledgerAmount.recoveredInterest}
            onChange={(e, value) => onChangeLedgerAmount('recoveredInterest', value)}
          />
        </Box>
        <Typography className={classes.subtitle} variant="subtitle1">Amount Due</Typography>
        <Box>
          <CustomCurrencyField 
            className={classes.field}
            label="Total"
            value={ledgerAmount.dueTotal}
            onChange={(e, value) => onChangeLedgerAmount('dueTotal', value)}
          />
          <CustomCurrencyField 
            className={classes.field}
            label="Principal"
            value={ledgerAmount.duePrincipal}
            onChange={(e, value) => onChangeLedgerAmount('duePrincipal', value)}
          />
          <CustomCurrencyField 
            className={classes.field}
            label="Interest"
            value={ledgerAmount.dueInterest}
            onChange={(e, value) => onChangeLedgerAmount('dueInterest', value)}
          />
        </Box>
        <Typography className={classes.subtitle} variant="subtitle1">Costs Advanced</Typography>
        <Box>
          <CustomCurrencyField 
            className={classes.field}
            label="Total"
            value={ledgerAmount.costAdvanced}
            onChange={(e, value) => onChangeLedgerAmount('costAdvanced', value)}
          />
        </Box>
      </Paper>
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.field} color="primary" variant="contained" onClick={onSave}>Save</Button>
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    caseInfo: state.data.caseInfo,
    ledgerInfo: state.data.ledgerInfo,
    ledgerAmount: state.data.ledgerAmount
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateLedgerInfo: (data) => {
      dispatch(updateLedgerInfo(data))
    },
    updateLedgerAmount: (data) => {
      dispatch(updateLedgerAmount(data))
    },
    getLedger: (caseId) => {
      dispatch(getLedger(caseId))
    },
    addLedger: (caseId, data) => {
      dispatch(addLedger(caseId, data))
    },
    updateLedger: (caseId, data) => {
      dispatch(updateLedger(caseId, data))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ledger)
