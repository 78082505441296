import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'

import DeleteUserDialog from './deleteDialog'
import { getUsers, deleteUser, updateUser } from '../../actions/users'
import { isAdmin } from '../../utils/const'

const useStyles = makeStyles(({ spacing }) => ({
  box: {
    marginBottom: spacing(3)
  },
  table: {
    minWidth: 650,
  },
  btn: {
    marginRight: spacing(1)
  }
}))

function Users({ user, users, getUsers, deleteUser, updateUser }) {
  const classes = useStyles()
  const history = useHistory()
  const [deleteDialogOpened, toggleDeleteDialog] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    if (user && !isAdmin(user)) {
      history.push('/dashboard')
    }
    if (users === null) {
      getUsers()
    }
  }, [])

  let data = users || []
  data = data.filter(d => !isAdmin(d))

  return (
    <Box>
      <Box className={classes.box} display="flex" justifyContent="end" alignItems="center">
        <Button variant="contained" color="primary" onClick={() => history.push('/users/new')}>Add a Affiliate</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>NO</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Client Company</TableCell>
              <TableCell>Client Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) =>  {
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.clientCompany}</TableCell>
                  <TableCell>{row.clientName}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell style={{textTransform: 'Capitalize'}}>{row.role}</TableCell>
                  <TableCell>{moment(row.createdAt).format('MM/DD/YYYY')}</TableCell>
                  <TableCell style={{textTransform: 'Capitalize'}}>{row.status || '-'}</TableCell>
                  <TableCell>
                    {
                      !isAdmin(row) && (
                        <IconButton 
                          className={classes.btn}
                          aria-label="delete"
                          onClick={() => {
                            toggleDeleteDialog(true)
                            setSelectedUser(row)
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )
                    }
                    {
                      row.status !== 'approved' && (
                        <Button 
                          className={classes.btn} 
                          color="primary"
                          size="small" 
                          onClick={() => {
                            updateUser(row.userId, { status: 'approved' })
                          }}
                        >
                          Approve
                        </Button>
                      )
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteUserDialog
        opened={deleteDialogOpened}
        handleClose={() => {
          setSelectedUser(null)
          toggleDeleteDialog(false)
        }}
        handleDelete={() => {
          deleteUser(selectedUser.userId)
          toggleDeleteDialog(false)
        }}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.users.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => {
      dispatch(getUsers())
    },
    deleteUser: (id) => {
      dispatch(deleteUser(id))
    },
    updateUser: (id, data) => {
      dispatch(updateUser(id, data))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users)
