import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import UploadIcon from '@material-ui/icons/CloudUpload'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import { useDropzone } from 'react-dropzone'
import Dialog from '../common/dialog'
import LoadingWrapper from '../common/loadingWrapper'
import { readFile } from '../../utils/functions'

import { getForms, uploadForms, deleteForm } from '../../actions/forms'

const useStyles = makeStyles(({ spacing, palette }) => ({
  modal: {
    width: 500
  },
  noFiles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: spacing(.5),
    padding: spacing(3),
    cursor: 'pointer'
  },
  fileName: {
    margin: spacing(1),
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: palette.primary.main
    }
  },
  activeText: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

function PrintDialog({ 
  isLoading,
  opened, 
  handleClose,
  forms,
  getForms,
  uploadForms,
  deleteForm 
}) {
  const { REACT_APP_S3_BUCKET_URL } = process.env
  const classes = useStyles()
  // const [formPreview, setFormPreview] = React.useState([])
  useEffect(() => {
    if (forms === null) {
      getForms()
    }
  }, [])
  const onDrop = React.useCallback((acceptedFiles) => {
    const promises = []
    acceptedFiles.forEach((file) => {
      promises.push(readFile(file))
    })
    Promise.all(promises).then(newFilesData => {
      // setFormPreview([...newFilesData])
      uploadForms(newFilesData)
    })
  }, [])
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop })

  return (
    <Dialog
      opened={opened}
      onClose={handleClose}
    >
      <LoadingWrapper visible={isLoading}>
      <DialogTitle>Uploaded Master Forms</DialogTitle>
      <DialogContent className={classes.modal}>
        <input name='doc' {...getInputProps({ accept: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' })} />
        {
          (forms === null || forms.length === 0) ? (
            <>
              <div {...getRootProps()} className='form-documents' />
              <Box className={classes.noFiles} onClick={open}>
                <UploadIcon fontSize='large' />
                <Typography variant="body2">Choose documents (.pdf, .doc, .docx)</Typography>
              </Box>
            </>
          ) : 
            forms.filter(f => f.caseId === "").map((form, index) => (
              <Box display="flex" alignItems="center" justifyContent="space-between" key={index}>
                <Box display="flex" alignItems="center">
                  <FileIcon fontSize="small" />
                  <Typography className={classes.fileName} variant="body2" onClick={() => window.open(REACT_APP_S3_BUCKET_URL + form.fromKey, '_blank')}>
                    {form.name}
                  </Typography>
                </Box>
                <Typography variant="caption" color="primary" className={classes.activeText} onClick={() => deleteForm(form.formId)}>
                  Remove
                </Typography>
              </Box>
            ))
        }
      </DialogContent>
      <DialogActions>
        {
          (forms !== null && forms.length > 0) && (
            <Button color="primary" onClick={open}>
              Upload
            </Button>
          )
        }
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
      </LoadingWrapper>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    forms: state.forms.forms
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getForms: () => {
      dispatch(getForms())
    },
    uploadForms: (data) => {
      dispatch(uploadForms(data))
    },
    deleteForm: (id) => {
      dispatch(deleteForm(id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintDialog)