import 'regenerator-runtime/runtime'
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleWare from 'redux-thunk'
import loggerMiddleware from 'redux-logger'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import isDev from 'isdev'

import initialStore from '../initialStore'
import rootReducer from '../../reducers'

const persistConfig = {
  key: 'authType',
  storage: storage,
  whitelist: ['auth'] // which reducer want to store
}

const pReducer = persistReducer(persistConfig, rootReducer)

let store

// If the app is running in dev, add in redux devtools.
if (isDev) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    store = createStore(
      pReducer,
      initialStore,
      composeEnhancers(
        applyMiddleware(
          thunkMiddleWare,
          loggerMiddleware
        )
      )
    )
} else {
  store = createStore(
    pReducer,
    initialStore,
    applyMiddleware(thunkMiddleWare)
  )
}

export const persistor = persistStore(store)

export default store
