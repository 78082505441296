import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, TextField, Typography, Button, IconButton } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/DeleteOutline'
import { cloneDeep } from 'lodash'

import DatePicker from '../../common/datepicker'
import {
  getPayout,
  updatePayout,
  addPayout
} from '../../../actions/payout'
import { payoutType, payoutInfoType } from '../../../utils/const'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingBottom: spacing(1)
  },
  paper: {
    padding: spacing(2),
    marginBottom: spacing(3)
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: spacing(1),
    marginRight: spacing(1)
  },
  field: {
    flex: 1,
    maxWidth: 300,
    margin: spacing(1)
  },
  label: {
    width: 150,
    margin: spacing(1)
  },
  label1: {
    width: 60,
    margin: spacing(1)
  },
  btn: {
    width: spacing(20),
    margin: spacing(1)
  }
}))

const Payout = ({
  caseInfo,
  selectedCaseId,
  payouts: pts,
  payoutInfo: pti,
  getPayout,
  updatePayout,
  addPayout
}) => {
  const classes = useStyles()
  const [payouts, setPayouts] = useState([payoutType])
  const [payoutInfo, setPayoutInfo] = useState(payoutInfoType)

  useEffect(() => {
    if (selectedCaseId) {
      getPayout(selectedCaseId)
    }
  }, [])

  useEffect(() => {
    setPayouts(pts)
    setPayoutInfo(pti)
  }, [pts, pti])

  const onChangePayoutInfo = (type, value) => {
    setPayoutInfo({...payoutInfo, [type]: value})
  }

  const onChange = (id, type, value) => {
    const pays = cloneDeep(payouts)
    pays.forEach((p, index) => {
      if (index === id) {
        p[type] = value
      }
    })
    setPayouts(pays)
  }

  const addItem = () => {
    const pays = cloneDeep(payouts)
    pays.push(payoutType)
    setPayouts(pays)
  }

  const removeItem = (index) => {
    if (payouts.length > 1) {
      const pays = cloneDeep(payouts)
      pays.splice(index, 1)
      setPayouts(pays)
    }
  }

  const onSave = () => {
    if (selectedCaseId) {
      updatePayout(selectedCaseId, { payouts, payoutInfo })
    } else {
      addPayout(caseInfo.caseId, { payouts, payoutInfo })
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Payout</Typography>
        {
          payouts.map((p, index) => (
            <Box display="flex" alignItems="flex-end" key={index}>
              <TextField className={classes.field} label="Paid To"
                value={p.paidTo} onChange={(e) => onChange(index, 'paidTo', e.target.value)}
              />
              <DatePicker className={classes.field} label="Date"
                value={p.date} onChange={(value) => onChange(index, 'date', value)}
              />
              <TextField className={classes.field} label="Description"
                value={p.description} onChange={(e) => onChange(index, 'description', e.target.value)}
              />
              <TextField className={classes.field} type="number" label="Amount"
                value={p.amount} onChange={(e) => onChange(index, 'amount', e.target.value)}
              />
              <IconButton onClick={() => removeItem(index)}><RemoveIcon /></IconButton>
            </Box>
          ))
        }
        <Button className={classes.btn} color="primary" variant="contained" onClick={() => addItem()}>Add</Button>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Percentage Setup</Typography>
        <Box display="flex" alignItems="flex-end">
          <Typography className={classes.label} variant="body1">Plaintiff Percentage:</Typography>
          <TextField className={classes.field} type="number" label="Percentage (%)"
            value={payoutInfo.plfPercentage} onChange={(e) => onChangePayoutInfo('plfPercentage', e.target.value)}
          />
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Typography className={classes.label} variant="body1">JMS Percentage:</Typography>
          <TextField className={classes.field} type="number" label="Percentage (%)"
            value={payoutInfo.jmsPercentage} onChange={(e) => onChangePayoutInfo('jmsPercentage', e.target.value)}
          />
        </Box>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Due By Plaintiff</Typography>
        <Box>
          <TextField className={classes.field} type="number" label="Total Costs"
            value={payoutInfo.plfDueCost} onChange={(e) => onChangePayoutInfo('plfDueCost', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Your Service Fee"
            value={payoutInfo.plfDueFee} onChange={(e) => onChangePayoutInfo('plfDueFee', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Total"
            value={payoutInfo.plfDueTotal} onChange={(e) => onChangePayoutInfo('plfDueTotal', e.target.value)}
          />
        </Box>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Payout Amounts</Typography>
        <Box display="flex" alignItems="flex-end">
          <Typography className={classes.label1} variant="body1">Plaintiff:</Typography>
          <TextField className={classes.field} type="number" label="Case Total"
            value={payoutInfo.plfTotal} onChange={(e) => onChangePayoutInfo('plfTotal', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Recovered"
            value={payoutInfo.plfRecovered} onChange={(e) => onChangePayoutInfo('plfRecovered', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Remaining"
            value={payoutInfo.plfRemaining} onChange={(e) => onChangePayoutInfo('plfRemaining', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Case Due"
            value={payoutInfo.plfCaseDue} onChange={(e) => onChangePayoutInfo('plfCaseDue', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="What you paid"
            value={payoutInfo.plfPaid} onChange={(e) => onChangePayoutInfo('plfPaid', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="What you owe"
            value={payoutInfo.plfOwe} onChange={(e) => onChangePayoutInfo('plfOwe', e.target.value)}
          />
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Typography className={classes.label1} variant="body1">You:</Typography>
          <TextField className={classes.field} type="number" label="Case Total"
            value={payoutInfo.youTotal} onChange={(e) => onChangePayoutInfo('youTotal', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Recovered"
            value={payoutInfo.youRecovered} onChange={(e) => onChangePayoutInfo('youRecovered', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Remaining"
            value={payoutInfo.youRemaining} onChange={(e) => onChangePayoutInfo('youRemaining', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Case Due"
            value={payoutInfo.youCaseDue} onChange={(e) => onChangePayoutInfo('youCaseDue', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="What you paid"
            value={payoutInfo.youPaid} onChange={(e) => onChangePayoutInfo('youPaid', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="What you owe"
            value={payoutInfo.youOwe} onChange={(e) => onChangePayoutInfo('youOwe', e.target.value)}
          />
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Typography className={classes.label1} variant="body1">Other:</Typography>
          <TextField className={classes.field} type="number" label="Case Total"
            value={payoutInfo.otherTotal} onChange={(e) => onChangePayoutInfo('otherTotal', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Recovered"
            value={payoutInfo.otherRecovered} onChange={(e) => onChangePayoutInfo('otherRecovered', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Remaining"
            value={payoutInfo.otherRemaining} onChange={(e) => onChangePayoutInfo('otherRemaining', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Case Due"
            value={payoutInfo.otherCaseDue} onChange={(e) => onChangePayoutInfo('otherCaseDue', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="What you paid"
            value={payoutInfo.otherPaid} onChange={(e) => onChangePayoutInfo('otherPaid', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="What you owe"
            value={payoutInfo.otherOwe} onChange={(e) => onChangePayoutInfo('otherOwe', e.target.value)}
          />
        </Box>
      </Paper>
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.field} color="primary" variant="contained" onClick={onSave}>Save</Button>
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    caseInfo: state.data.caseInfo,
    payouts: state.payout.payouts,
    payoutInfo: state.payout.payoutInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPayout: (caseId) => {
      dispatch(getPayout(caseId))
    },
    addPayout: (caseId, data) => {
      dispatch(addPayout(caseId, data))
    },
    updatePayout: (caseId, data) => {
      dispatch(updatePayout(caseId, data))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payout)
