import _ from 'lodash'
import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  forms: null,
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.UPLOAD_FORMS_SUCCESS:
      newState.forms = [...newState.forms, ...action.payload]
      return newState

    case ActionTypes.GET_FORMS_SUCCESS:
      newState.forms = action.payload
      return newState

    case ActionTypes.DELETE_FORM_SUCCESS:
      newState.forms = newState.forms && newState.forms.filter(u => u.formId !== action.payload.formId)
      return newState
      

    default:
      return state
  }
}

export default reducer
