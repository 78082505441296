import * as ActionTypes from '../utils/actionTypes'
import * as ApiServices from '../services/service'
import {apiLoading, apiFailed} from './loading'

export const getParties = (caseId) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getParties(caseId)
      .then(response => {
        dispatch({
          type: ActionTypes.GET_PARTIES_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const updateParties = (caseId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.updateParties(caseId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_PARTIES_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const addParties = (caseId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.addParties(caseId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.ADD_PARTIES_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}
