import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import { register } from '../../actions/auth'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: 1,
    backgroundColor: theme.palette.secondary.main
  },
  content: {
    width: '100%', // Fix IE11 issue.
    marginTop: 1,
  },
  signInBtn: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  copyright: {
    marginTop: theme.spacing(5)
  }
}))

function Copyright(props) {
  return (
    <Typography variant="body2" color="textSecondary" align="center" {...props}>
      Copyright © JRMS 2021.
    </Typography>
  )
}

function Signup({ isLoggedIn, register }) {
  const classes = useStyles()
  const history = useHistory()
  const [state, setState] = useState({
    name: '',
    email: '',
    password: ''
  })

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/dashboard')
    }
  }, [])

  const onRegister = () => {
    if (state.name !== '' && state.email !== '' && state.password !== '') {
      register(state)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.wrapper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5"> Sign Up</Typography>
        <Box component="form" noValidate className={classes.content}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="name"
            name="name"
            // autoComplete="email"
            autoFocus
            value={state.name}
            onChange={e => setState({...state, name: e.target.value})}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            // autoComplete="email"
            autoFocus
            value={state.email}
            onChange={e => setState({...state, email: e.target.value})}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            // autoComplete="current-password"
            value={state.password}
            onChange={e => setState({...state, password: e.target.value})}
          />
          <Button className={classes.signInBtn} fullWidth variant="contained" color="primary" onClick={onRegister}>
            Sign Up
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/login" variant="body2">
                {"Already have an account? Sign in"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright className={classes.copyright} />
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.auth.isLoggedIn
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    register: (data) => {
      dispatch(register(data, ownProps.history))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
  (Signup)
)
