import { toast } from 'react-toastify'

export const notificationMessage = (type, message) => {
  toast.configure({
    autoClose: 3000,
    draggable: false,
    position: 'top-center',
    hideProgressBar: true
  })

  toast[type](message)

  return `${type}, ${message}`
}

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()

    // Read the image via FileReader API and save image result in state.
    reader.onload = function (e) {
      // Add the file name to the data URL
      let dataURL = e.target.result
      dataURL = dataURL.replace(';base64', `;name=${file.name};base64`)
      resolve({ file, dataURL })
    }

    reader.readAsDataURL(file)
  })
}

export const sortCompareFuncByDate = (a, b, field) => {
  const bTimestamp = b[field] ? Date.parse(b[field]) : 0
  const aTimestamp = a[field] ? Date.parse(a[field]) : 0
  if (bTimestamp < aTimestamp) {
    return 1
  }
  if (bTimestamp > aTimestamp) {
    return -1
  }
  return 0
}
