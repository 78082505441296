import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useDropzone } from 'react-dropzone'
import Dialog from '../common/dialog'

import { getForms, uploadForms, deleteForm } from '../../actions/forms'
import { updateCase } from '../../actions/data'
import { readFile } from '../../utils/functions'

const useStyles = makeStyles(({ spacing }) => ({
  modal: {
    width: 500
  },
  form: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}))

export function SelectForms({
  opened,
  handleClose,
  forms,
  getForms,
  uploadForms,
  selectedCase,
}) {
  const classes = useStyles()
  useEffect(() => {
    if(forms === null) {
      getForms()
    }
  }, [])

  const onDrop = React.useCallback((acceptedFiles) => {
    const promises = []
    acceptedFiles.forEach((file) => {
      promises.push(readFile(file))
    })
    Promise.all(promises).then(newFilesData => {
      uploadForms(newFilesData, selectedCase.caseId)
    })
  }, [selectedCase])
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop })

  const uploadedForms = forms && selectedCase && forms.filter(f => f.caseId === selectedCase.caseId)

  return (
    <Dialog
      opened={opened}
      onClose={handleClose}
    >
      <DialogTitle>Upload Forms</DialogTitle>
      <DialogContent className={classes.modal}>
        {/* {
          masterForms !== null && masterForms.length > 0 ? (
            <>
              <Typography>Master Forms</Typography>
              <Select
                className={classes.select}
                label="Forms"
                multiple={true}
                options={masterFormsSelectData}
                value={selectedMFormIds}
                onChange={(value) => setSelectedMFormIds(value)}
              />
              {
                selectedMFormIds.map(formId => {
                  const form = masterForms.filter(f => f.formId === formId)[0]
                  return (
                    <Box className={classes.form} key={form.formId}>
                      <Typography className={classes.fileName} variant="body2">
                        {form.name}
                      </Typography>
                      <IconButton onClick={() => window.open(REACT_APP_S3_BUCKET_URL + form.fromKey, '_blank')}>
                        <OpenInNewIcon fontSize="small" />
                      </IconButton>
                      <Box mx={2}>
                        <IconButton onClick={() => deleteSelectedMasterForms(form.formId)}>
                          <CloseOutlined fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  )
                })
              }
            </>
          ) : (
            <Typography>Please upload the forms</Typography>
          )
        } */}
        <Box mb={2}>
          {
            uploadedForms !== null && uploadedForms.length > 0 ? (
              <>
                {
                  uploadedForms.map(form => (
                    <Box className={classes.form} key={form.formId} my={2}>
                      <Typography className={classes.fileName} variant="body2">
                        {form.name}
                      </Typography>
                    </Box>
                  ))
                }
              </>
            ) : (
              <Typography variant="body2">
                There is no form uploaded for this case
              </Typography>
            )
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <input name='doc' {...getInputProps({ accept: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' })} />
        <Button 
          {...getRootProps()}
          onClick={open} color="primary" variant="contained">
          Upload New Form
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    forms: state.forms.forms
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (id, data) => {
      return dispatch(updateCase(id, data))
    },
    getForms: () => {
      dispatch(getForms())
    },
    uploadForms: (data, caseId) => {
      dispatch(uploadForms(data, caseId))
    },
    deleteForm: (id) => {
      dispatch(deleteForm(id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectForms)