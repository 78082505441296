import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ViewIcon from '@material-ui/icons/Description'
import BuildIcon from '@material-ui/icons/Build'
import PeopleIcon from '@material-ui/icons/People'
import ExitIcon from '@material-ui/icons/ExitToApp'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { logout } from '../actions/auth'
import { isAdmin } from '../utils/const'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }
}))

export const navLinks = [{
  label: 'Dashboard',
  link: '/dashboard',
  icon: DashboardIcon
}, {
  label: 'Case',
  link: '/case',
  icon: ViewIcon
}, {
  label: 'Closed Cases',
  link: '/closed-cases',
  icon: CheckCircleIcon
}, {
  label: 'Settings',
  link: '/settings',
  icon: BuildIcon
}, {
  label: 'Affiliates',
  link: '/users',
  icon: PeopleIcon
}, {
  label: 'Log out',
  link: '/login',
  icon: ExitIcon
}]

function DrawerWrapper({ user, drawerOpened, handleDrawerClose, logout }) {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={drawerOpened}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {navLinks.map((list, index) => {
          if (user && !isAdmin(user) && list.link === '/users') { // Users page is available only for admin
            return null
          }
          const Icon = list.icon
          const handler = () => {
            if (list.label === 'Log out') {
              logout()
            }
            history.push(list.link)
          }
          return (
            <ListItem button onClick={handler} key={index}>
              <ListItemIcon><Icon /></ListItemIcon>
              <ListItemText primary={list.label} />
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerWrapper)
