import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import CaseDataGrid from './caseDataGrid'
import PrintDialog from './printDialog'

import { getCases } from '../../actions/data'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    
  },
  search: {
    width: 400,
    background: 'white'
  },
  table: {
    background: 'white',
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  formBtn: {
    marginRight: spacing(2)
  }
}))

function MyCases({user, cases, getCases}) {
  const classes = useStyles()
  const history = useHistory()
  const [printDialogOpened, togglePrintDialog] = useState(false)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (cases === null) {
      getCases(user.userId)
    }
  }, [])

  let data = cases || []
  if (searchText) {
    const text = searchText.toLowerCase()
    data = data.filter(item => (
      item.caseNumber.toLowerCase().includes(text) || 
      item.caseState.toLowerCase().includes(text) || 
      item.plaintiffs.reduce((acc, cur) => acc || cur.name.toLowerCase().includes(text), false) || 
      item.defendants.reduce((acc, cur) => acc || cur.name.toLowerCase().includes(text), false)
    ))
  }
  
  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField className={classes.search} label="Search" variant="outlined"
          value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <Box display="flex" justifyContent="center" alignItems="center">
          {/* <Button className={classes.formBtn} variant="contained" color="primary" onClick={() => togglePrintDialog(true)}>Master Forms</Button> */}
          <Button variant="contained" color="primary" onClick={() => history.push('/case')}>+ New Case</Button>
        </Box>
      </Box>
      <div className={classes.table}>
        <CaseDataGrid type="myCases" data={data} />
      </div>

      <PrintDialog opened={printDialogOpened} handleClose={() => togglePrintDialog(false)} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    cases: state.data.cases,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCases: (userId) => {
      dispatch(getCases(userId))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCases)
