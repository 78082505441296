import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, TextField, Typography, Button } from '@material-ui/core'
import Select from '../common/select'

import { logout } from '../../actions/auth'
import { getUsers, changePassword, resetPassword } from '../../actions/users'
import { notificationMessage } from '../../utils/functions'
import { isAdmin } from '../../utils/const'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: spacing(1),
    marginRight: spacing(1)
  },
  paper: {
    padding: spacing(2),
    marginBottom: spacing(2)
  },
  field: {
    width: 300,
    margin: spacing(1)
  },
  btn: {
    width: spacing(20),
    margin: spacing(1)
  }
}))

function Settings({ user, changePassword, resetPassword, users, getUsers }) {
  const classes = useStyles()
  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  })
  const [affiliatePassword, setAffiliatePassword] = useState({
    newPassword: '',
    confirmPassword: ''
  })
  const [selectedUser, setSelectedUser] = useState('')

  useEffect(() => {
    if (users === null) {
      getUsers()
    }
  }, [])

  const changePasswordHandler = () => {
    if (state.newPassword === state.confirmPassword && state.newPassword !== '') {
      if (user) {
        changePassword(user.userId, state)
      }
    } else {
      notificationMessage('error', 'Confirm password is wrong.')
    }
  }

  const resetAffiliatePasswordHandler = () => {
    if (selectedUser) {
      if (affiliatePassword.newPassword === affiliatePassword.confirmPassword && affiliatePassword.newPassword !== '') {
        if (user) {
          resetPassword(selectedUser, affiliatePassword)
        }
      } else {
        notificationMessage('error', 'Confirm password is wrong.')
      }
    }
  }

  let userOptions = []
  if (users) {
    users.forEach(user => {
      !isAdmin(user) && userOptions.push({label: user.name, value: user.userId})
    })
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Change Password</Typography>
        <Box display="flex" alignItems="flex-end">
          <TextField 
            className={classes.field} 
            type="password"
            label="Current Password" 
            required
            value={state.oldPassword}
            onChange={e => setState({...state, oldPassword: e.target.value})}
          />
          <TextField 
            className={classes.field} 
            type="password"
            label="New Password" 
            required
            value={state.newPassword}
            onChange={e => setState({...state, newPassword: e.target.value})}
          />
          <TextField 
            className={classes.field} 
            type="password"
            label="Confirm Password" 
            required
            value={state.confirmPassword}
            onChange={e => setState({...state, confirmPassword: e.target.value})}
          />
          <Button
            className={classes.btn}
            color="primary"
            variant="contained"
            onClick={changePasswordHandler}
            disabled={!state.oldPassword || !state.newPassword}>Change</Button>
        </Box>
        {
          user && isAdmin(user) && false && (
            <>
              <Typography className={classes.subtitle} variant="subtitle1">Invite Users</Typography>
              <Box display="flex" alignItems="flex-end">
                <TextField className={classes.field} label="Email" />
                <Button className={classes.btn} color="primary" variant="contained">Invite</Button>
              </Box>
            </>
          )
        }
      </Paper>
      {isAdmin(user) && (
        <Paper className={classes.paper}>
          <Typography className={classes.subtitle} variant="subtitle1">Reset Affiliate's Password</Typography>
          <Box display="flex" alignItems="flex-end">
            <Select className={classes.field} label="Affiliate" options={userOptions}
              value={selectedUser} onChange={setSelectedUser}
            />
            <TextField 
              className={classes.field} 
              type="password"
              label="New Password" 
              required
              value={affiliatePassword.newPassword}
              onChange={e => setAffiliatePassword({...affiliatePassword, newPassword: e.target.value})}
            />
            <TextField 
              className={classes.field} 
              type="password"
              label="Confirm Password"
              required
              value={affiliatePassword.confirmPassword}
              onChange={e => setAffiliatePassword({...affiliatePassword, confirmPassword: e.target.value})}
            />
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              onClick={resetAffiliatePasswordHandler}
              disabled={!selectedUser || !affiliatePassword.newPassword}
              >Reset</Button>
          </Box>
        </Paper>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.users.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      return dispatch(logout())
    },
    changePassword: (id, data) => {
      return dispatch(changePassword(id, data))
    },
    resetPassword: (id, data) => {
      return dispatch(resetPassword(id, data))
    },
    getUsers: () => {
      dispatch(getUsers())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)
