import * as ActionTypes from '../utils/actionTypes'

export const apiLoading = () => ({
  type: ActionTypes.API_LOADING
})

export const apiFailed = (error) => ({
  type: ActionTypes.API_FAILED,
  payload: error
})
