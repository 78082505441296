import { combineReducers } from 'redux'

import loading from './loading'
import auth from './auth'
import data from './data'
import parties from './parties'
import payout from './payout'
import events from './events'
import users from './users'
import forms from './forms'

const RootReducer = combineReducers({
  loading,
  auth,
  data,
  parties,
  payout,
  events,
  users,
  forms
})

export default RootReducer
