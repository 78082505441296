import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { makeStyles } from '@material-ui/core/styles'

import store, { persistor } from './store/configureStore'
import AppContent from './AppContent'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100vh'
  }
}))

function App() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AppContent />
        </PersistGate>
      </Provider>
    </div>
  )
}

export default App
