import { requestService } from './request'
import * as ApiUrls from './apiUrl'
import store from '../store/configureStore'
import { logout } from '../actions/auth'
import { notificationMessage } from '../utils/functions'

export const commonService = (request, token = true) => {
  let reqObj = {
    method: request.method,
    url: request.url
  }
  const state = store.getState();
  if (token && state.auth.token) {
    reqObj = Object.assign(reqObj, { token: `Bearer ${state.auth.token}` })
  }
  if (request.data) {
    reqObj = Object.assign(reqObj, { data: request.data })
  }
  if (request.queryParams) {
    reqObj = Object.assign(reqObj, { queryParams: request.queryParams })
  }
  return requestService(reqObj)
    .then(response => {
      return response
    }).catch(error => {
      if (error.response.data.error === 'jwt expired') {
        notificationMessage('error', 'Token was expired. Log in again, please.')
        store.dispatch(logout())
      }
      return Promise.reject(error.response.data)
    })
}

// Auth services

export const authenticate = (data) => {
  return commonService({
    method: 'POST',
    url: ApiUrls.AUTH_ENDPOINT + '/authenticate',
    data
  }, false)
}

export const register = (data) => {
  return commonService({
    method: 'POST',
    url: ApiUrls.AUTH_ENDPOINT + '/register',
    data
  }, false)
}

// User services

export const getUsers = (data) => {
  return commonService({
    method: 'GET',
    url: ApiUrls.USER_ENDPOINT,
    data
  })
}

export const deleteUser = (id) => {
  return commonService({
    method: 'DELETE',
    url: ApiUrls.USER_ENDPOINT + `/${id}`
  })
}

export const changePassword = (id, data) => {
  return commonService({
    method: 'PUT',
    url: ApiUrls.USER_ENDPOINT + `/${id}/change-password`,
    data
  })
}

export const resetPassword = (id, data) => {
  return commonService({
    method: 'PUT',
    url: ApiUrls.USER_ENDPOINT + `/${id}/reset-password`,
    data
  })
}

export const updateUser = (id, data) => {
  return commonService({
    method: 'PUT',
    url: ApiUrls.USER_ENDPOINT + `/${id}`,
    data
  })
}

// Forms services

export const uploadForms = (data, caseId) => {
  const formData = new FormData()
  data.forEach(file => {
    formData.append('forms', file.file)
  })
  formData.append('caseId', caseId)
  
  return commonService({
    method: 'POST',
    url: ApiUrls.FORMS_ENDPOINT,
    data: formData
  })
}

export const getForms = () => {
  return commonService({
    method: 'GET',
    url: ApiUrls.FORMS_ENDPOINT,
  })
}

export const deleteForm = (id) => {
  return commonService({
    method: 'DELETE',
    url: ApiUrls.FORMS_ENDPOINT + `/${id}`
  })
}

// Case services

export const getCaseTypes = () => {
  return commonService({
    method: 'GET',
    url: ApiUrls.CASE_ENDPOINT + '/types'
  })
}

export const getCases = (status = 'open', userId) => {
  let queryParams = `?status=${status}`
  if (userId) {
    queryParams += `&userId=${userId}`
  }
  return commonService({
    method: 'GET',
    url: ApiUrls.CASE_ENDPOINT + queryParams
  })
}

export const getCaseDetail = (id) => {
  return commonService({
    method: 'GET',
    url: ApiUrls.CASE_ENDPOINT + `/${id}`
  })
}

export const updateCase = (id, data) => {
  return commonService({
    method: 'PUT',
    url: ApiUrls.CASE_ENDPOINT + `/${id}`,
    data
  })
}

export const addCase = (data) => {
  return commonService({
    method: 'POST',
    url: ApiUrls.CASE_ENDPOINT,
    data
  })
}

export const deleteCase = (id) => {
  return commonService({
    method: 'DELETE',
    url: ApiUrls.CASE_ENDPOINT + `/${id}`
  })
}

// Ledger services

export const getLedger = (caseId) => {
  return commonService({
    method: 'GET',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/ledger`
  })
}

export const updateLedger = (caseId, data) => {
  return commonService({
    method: 'PUT',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/ledger`,
    data
  })
}

export const addLedger = (caseId, data) => {
  return commonService({
    method: 'POST',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/ledger`,
    data
  })
}

// Parties services

export const getParties = (caseId) => {
  return commonService({
    method: 'GET',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/parties`
  })
}

export const updateParties = (caseId, data) => {
  return commonService({
    method: 'PUT',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/parties`,
    data
  })
}

export const addParties = (caseId, data) => {
  return commonService({
    method: 'POST',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/parties`,
    data
  })
}

// Events services

export const getEvents = (caseId) => {
  return commonService({
    method: 'GET',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/events`
  })
}

export const updateEvents = (caseId, data) => {
  return commonService({
    method: 'PUT',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/events`,
    data
  })
}

export const addEvents = (caseId, data) => {
  return commonService({
    method: 'POST',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/events`,
    data
  })
}

// Payout services

export const getPayout = (caseId) => {
  return commonService({
    method: 'GET',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/payout`
  })
}

export const updatePayout = (caseId, data) => {
  return commonService({
    method: 'PUT',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/payout`,
    data
  })
}

export const addPayout = (caseId, data) => {
  return commonService({
    method: 'POST',
    url: ApiUrls.CASE_ENDPOINT + `/${caseId}/payout`,
    data
  })
}
