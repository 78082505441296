import * as ActionTypes from '../utils/actionTypes'
import * as ApiServices from '../services/service'
import {apiLoading, apiFailed} from './loading'

export const updateEventsInfo = (data) => {
  return {
    type: ActionTypes.UPDATE_EVENTS_INFO,
    payload: data
  }
}

export const getEvents = (caseId) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getEvents(caseId)
      .then(response => {
        dispatch({
          type: ActionTypes.GET_EVENTS_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const updateEvents = (caseId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.updateEvents(caseId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_EVENTS_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const addEvents = (caseId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.addEvents(caseId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.ADD_EVENTS_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}
