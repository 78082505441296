export const states = [
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'IllinoisIndiana', value: 'IllinoisIndiana' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'MontanaNebraska', value: 'MontanaNebraska' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'PennsylvaniaRhode Island', value: 'PennsylvaniaRhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Washington', value: 'Washington' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Wyoming', value: 'Wyoming' },
]

export const caseStatus = [
  { label: 'Open', value: 'open' },
  { label: 'Closed', value: 'close' }
]

export const ledgerType = {
  startDate: null,
  endDate: null,
  days: '',
  description: '',
  recovery: '',
  principal: '',
  interest: ''
}

export const plfType = {
  name: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  email: '',
  phone: '',
}

export const dftType = {
  name: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  email: '',
  phone: '',
  socialSecurity: '',
  dob: null,
}

export const partiesInfoType = {
  comments: '',
}

export const payoutType = {
  paidTo: '',
  date: null,
  description: '',
  amount: '',
}

export const payoutInfoType = {
  plfPercentage: '50',
  jmsPercentage: '50',
  plfDueCost: '',
  plfDueFee: '',
  plfDueTotal: '',
  plfTotal: '',
  plfRecovered: '',
  plfRemaining: '',
  plfCaseDue: '',
  plfPaid: '',
  plfOwe: '',
  youTotal: '',
  youRecovered: '',
  youRemaining: '',
  youCaseDue: '',
  youPaid: '',
  youOwe: '',
  otherTotal: '',
  otherRecovered: '',
  otherRemaining: '',
  otherCaseDue: '',
  otherPaid: '',
  otherOwe: '',
}

export const eventType = {
  description: '',
  date: null,
}

export const isAdmin = (user) => user.role === 'super'
