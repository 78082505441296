import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, TextField, Typography, Button, IconButton } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/DeleteOutline'
import { cloneDeep } from 'lodash'

import DatePicker from '../../common/datepicker'
import {
  getEvents,
  updateEvents,
  addEvents
} from '../../../actions/events'
import { eventType } from '../../../utils/const'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingBottom: spacing(1)
  },
  paper: {
    padding: spacing(2),
    marginBottom: spacing(3)
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: spacing(1),
    marginRight: spacing(1)
  },
  field: {
    width: 300,
    margin: spacing(1)
  },
  btn: {
    width: spacing(20),
    margin: spacing(1)
  }
}))

const Events = ({
  caseInfo,
  selectedCaseId,
  events: ets,
  getEvents,
  updateEvents,
  addEvents
}) => {
  const classes = useStyles()
  const [events, setEvents] = useState([eventType])

  useEffect(() => {
    if (selectedCaseId) {
      getEvents(selectedCaseId)
    }
  }, [])

  useEffect(() => {
    setEvents(ets)
  }, [ets])

  const onChange = (id, type, value) => {
    const evts = cloneDeep(events)
    evts.forEach((p, index) => {
      if (index === id) {
        p[type] = value
      }
    })
    setEvents(evts)
  }

  const addItem = () => {
    const evts = cloneDeep(events)
    evts.push(eventType)
    setEvents(evts)
  }

  const removeItem = (index) => {
    if (events.length > 1) {
      const evts = cloneDeep(events)
      evts.splice(index, 1)
      setEvents(evts)
    }
  }

  const onSave = () => {
    if (selectedCaseId) {
      updateEvents(selectedCaseId, { events })
    } else {
      addEvents(caseInfo.caseId, { events })
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Events Tracking</Typography>
        {
          events.map((e, index) => (
            <Box display="flex" alignItems="flex-end" key={index} my={2}>
              <TextField className={classes.field} label="Events Description" multiline value={e.description} onChange={(e) => onChange(index, 'description', e.target.value)} />
              <DatePicker className={classes.field} label="Date" value={e.date} onChange={(value) => onChange(index, 'date', value)} />
              <IconButton onClick={() => removeItem(index)}><RemoveIcon /></IconButton>
            </Box>
          ))
        }
        <Button className={classes.btn} color="primary" variant="contained" onClick={() => addItem()}>Add</Button>
      </Paper>
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.field} color="primary" variant="contained" onClick={onSave}>Save</Button>
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    caseInfo: state.data.caseInfo,
    events: state.events.events,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEvents: (caseId) => {
      dispatch(getEvents(caseId))
    },
    addEvents: (caseId, data) => {
      dispatch(addEvents(caseId, data))
    },
    updateEvents: (caseId, data) => {
      dispatch(updateEvents(caseId, data))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events)
