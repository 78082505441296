/**
 * api base url
 * type { String }
 */
export const { REACT_APP_API_URL } = process.env

/**
 * api version url
 * type { String }
 */
export const VERSION_URL = `${REACT_APP_API_URL}/v1`

export const AUTH_ENDPOINT = `${VERSION_URL}/auth`
export const USER_ENDPOINT = `${VERSION_URL}/user`
export const CASE_ENDPOINT = `${VERSION_URL}/case`
export const LEDGER_ENDPOINT = `${VERSION_URL}/ledger`
export const FORMS_ENDPOINT = `${VERSION_URL}/form`
