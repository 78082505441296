import * as ActionTypes from '../utils/actionTypes'
import * as ApiServices from '../services/service'
import {apiLoading, apiFailed} from './loading'

export const uploadForms = (data, caseId = '') => {
  return (dispatch, getState) => {
    dispatch(apiLoading())  
    return ApiServices.uploadForms(data, caseId)
      .then(response => {
        dispatch({
          type: ActionTypes.UPLOAD_FORMS_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const getForms = () => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getForms()
      .then(response => {
        dispatch({
          type: ActionTypes.GET_FORMS_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const deleteForm = (formId) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.deleteForm(formId)
      .then(response => {
        dispatch({
          type: ActionTypes.DELETE_FORM_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}
