import moment from 'moment'

export const validUntilDateFormula = (validityYears, startDate) => {
  const validUntilDate = (validityYears && startDate && moment(startDate).add(validityYears, 'y').format('MM/DD/YYYY')) || ''
  return validUntilDate
}

export const startingAmountFormula = (judgement, priorAmount) => {
  const prior = priorAmount || 0
  const pAmount = (judgement && (judgement - prior)) || ''
  return pAmount
}

export const totalValueFormula = (interest, validityYears, pAmount) => {
  const aAmount = (interest && validityYears && pAmount && (pAmount * (1 + interest / 100 * validityYears))) || ''
  return aAmount
}

export const totalInterestFormula = (aAmount, pAmount) => {
  return aAmount - pAmount
}

export const totalInterestFormula1 = (judgement, priorAmount, interest, validityYears) => {
  const pAmount = startingAmountFormula(judgement, priorAmount)
  const aAmount = totalValueFormula(interest, validityYears, pAmount)
  return aAmount - pAmount
}

export const dailyInterestFormula = (totalInterest, validityYears) => {
  return totalInterest / (validityYears * 365)
}
