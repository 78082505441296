import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'inline-flex'
  },
}))

export default function AutoComplete({ className, label, value, onChange, options}) {
  const classes = useStyles()

  // const handleChange = (event) => {
  //   onChange(event.target.value)
  // }

  return (
    <Autocomplete
      className={`${classes.formControl} ${className}`}
      freeSolo
      disableClearable
      options={options}
      value={value}
      onChange={(e, value) => onChange(value)} 
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
    />
  )
}
