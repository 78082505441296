import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoggedIn: false,
  token: null,
  user: null
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {

    case ActionTypes.LOG_OUT:
      newState.isLoggedIn = false
      newState.token = null
      return newState

    case ActionTypes.LOGIN_SUCCESS:
      newState.isLoggedIn = true
      newState.token = action.payload.token
      newState.user = action.payload.user
      return newState

    case ActionTypes.SIGNUP_SUCCESS:
      
      return newState

    default:
      return state
  }
}

export default reducer
