import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import Main from './main'
import Parties from './parties'
import Ledger from './ledger'
import Payout from './payout'
import Events from './events'
import Forms from './forms'

import { getCases, getCaseDetail, cleanCaseInfo } from '../../actions/data'

const tabs = [
  'Main',
  'Parties',
  'Ledger',
  'Payout',
  'Events',
  'Forms'
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  }
}))

function Case({
  user,
  cases,
  getCases,
  caseDetail,
  caseInfo,
  getCaseDetail,
  cleanCaseInfo,
  ...props
}) {
  const classes = useStyles()

  const [value, setValue] = useState('0')

  useEffect(() => {
    if (cases === null) {
      getCases(user.userId)
    }
  }, [])

  useEffect(() => {
    if (props.match.params.caseId) {
      getCaseDetail(props.match.params.caseId)
    } else {
      cleanCaseInfo()
      setValue('0')
    }
  }, [props.match.params.caseId])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const disabled = !props.match.params.caseId && !caseInfo.caseId

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static" color="default">
          <TabList onChange={handleChange} aria-label="tabs" indicatorColor="primary" textColor="primary">
            {tabs.map((item, index) => {
              return (
                <Tab label={item} value={index.toString()} key={index.toString()} disabled={index !== 0 && disabled} />
              )
            })}
          </TabList>
        </AppBar>
        <TabPanel value='0'>
          <Main selectedCaseId={props.match.params.caseId} />
        </TabPanel>
        <TabPanel value='1'>
          <Parties selectedCaseId={props.match.params.caseId}/>
        </TabPanel>
        <TabPanel value='2'>
          <Ledger selectedCaseId={props.match.params.caseId}/>
        </TabPanel>
        <TabPanel value='3'>
          <Payout selectedCaseId={props.match.params.caseId}/>
        </TabPanel>
        <TabPanel value='4'>
          <Events selectedCaseId={props.match.params.caseId}/>
        </TabPanel>
        <TabPanel value='5'>
          <Forms selectedCaseId={props.match.params.caseId}/>
        </TabPanel>
      </TabContext>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cases: state.data.cases,
    caseDetail: state.data.caseDetail,
    caseInfo: state.data.caseInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCases: (userId) => {
      dispatch(getCases(userId))
    },
    getCaseDetail: (caseId) => {
      dispatch(getCaseDetail(caseId))
    },
    cleanCaseInfo: () => {
      dispatch(cleanCaseInfo())
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Case))
