import React from 'react'
import { TextField, InputAdornment } from '@material-ui/core'

const DefaultCurrencyField = ({
  className,
  label,
  value,
  onChange,
  disabled
}) => {
  
  return (
    <TextField
      className={className}
      type="number"
      label={label}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      value={value}
      onChange={onChange} 
      disabled={disabled}
    />
  )
}

export default DefaultCurrencyField
