import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '../common/dialog'

const useStyles = makeStyles(({ spacing, palette }) => ({
  modal: {
    width: 500
  },
}))

export default function DeleteUserDialog({ opened, handleClose, handleDelete }) {
  const classes = useStyles()

  return (
    <Dialog
      opened={opened}
      onClose={handleClose}
    >
      <DialogTitle>Delete</DialogTitle>
      <DialogContent className={classes.modal}>
        Are you sure to delete a user?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} variant="contained" color="primary">
          Delete
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
