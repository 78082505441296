import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, IconButton } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import DeleteIcon from '@material-ui/icons/Delete'

import DeleteDialog from '../dashboard/deleteConfirm'

import { getClosedCases, deleteCase } from '../../actions/data'
import {
  startingAmountFormula,
  totalValueFormula,
} from '../../utils/formula'
import { isAdmin } from '../../utils/const'

const useStyles = makeStyles(({ spacing }) => ({
  table: {
    background: 'white',
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  status: {
    textTransform: 'capitalize'
  }
}))

function ClosedCaseList ({user, closedCases, getClosedCases, deleteCase}) {
  const classes = useStyles()
  const [deleteDialogOpened, toggleDeleteDialog] = useState(false)
  const [selectedCase, setSelectedCase] = useState(null)

  useEffect(() => {
    if (closedCases === null) {
      getClosedCases(isAdmin(user) ? null : user.userId)
    }
  }, [])

  const columns = [
    { 
      field: 'user',
      headerName: 'Affiliate',
      minWidth: 110,
      valueGetter: ({row}) => row.user ? row.user.name : '-'
    },
    { 
      field: 'status',
      headerName: 'Status',
      minWidth: 110,
      valueGetter: ({row}) => row.caseStatus || 'Open',
      renderCell: (data) => (<span className={classes.status}>{data.formattedValue}</span>),
      sortComparator: (v1, v2, param1, param2) => param1.value.localeCompare(param2.value),
    },
    { 
      field: 'caseNumber',
      headerName: 'Case',
      minWidth: 130,
      sortComparator: (v1, v2, param1, param2) => param1.value.localeCompare(param2.value),
    },
    { 
      field: 'plaintiffs',
      headerName: 'Plaintiff',
      minWidth: 150,
      valueGetter: ({row}) => row.plaintiffs && row.plaintiffs.length > 0 && row.plaintiffs.map((item, index) => item.name).toString() || '',
      renderCell: (data) => data.formattedValue.replace(',', ', '),
      sortComparator: (v1, v2, param1, param2) =>  param1.value.localeCompare(param2.value),
    },
    { 
      field: 'defendants',
      headerName: 'Defendant',
      minWidth: 150,
      valueGetter: ({row}) => row.defendants && row.defendants.length > 0 && row.defendants.map((item, index) => item.name).toString() || '',
      renderCell: (data) => data.formattedValue.replace(',', ', '),
      sortComparator: (v1, v2, param1, param2) =>  param1.value.localeCompare(param2.value),
    },
    { 
      field: 'caseState',
      minWidth: 130,
      headerName: 'Case State',
      sortComparator: (v1, v2, param1, param2) => param1.value.localeCompare(param2.value),
    },
    { 
      field: 'caseTotal',
      headerName: 'Case Total',
      minWidth: 130,
      valueGetter: ({row}) => {
        const startingAmount = startingAmountFormula(row.judgement, row.priorAmount)
        const totalValue = totalValueFormula(row.interest, row.validityYears, startingAmount)
        return totalValue && totalValue.toFixed(2)
      },
      renderCell: (data) => data.formattedValue ? `$${data.formattedValue}` : '',
      sortComparator: (v1, v2, param1, param2) => Number(param1.value) - Number(param2.value),
    },
    { 
      field: 'totalRecovered',
      headerName: 'Recovered',
      minWidth: 130,
      renderCell: (data) => data.formattedValue ? `$${data.formattedValue}` : '',
      sortComparator: (v1, v2, param1, param2) => Number(param1.value) - Number(param2.value),
    },
    { 
      field: 'ledgerAmount',
      headerName: 'Remaining',
      minWidth: 130,
      valueGetter: ({row}) => row.ledgerAmount && row.ledgerAmount.length > 0 && row.ledgerAmount[0].dueTotal,
      renderCell: (data) => data.formattedValue ? `$${data.formattedValue}` : '',
      sortComparator: (v1, v2, param1, param2) => Number(param1.value) - Number(param2.value),
    },
    { 
      field: 'options',
      headerName: 'Options',
      minWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: ({row}) => (
        <div className='test'>
          <IconButton 
            className={classes.btn}
            aria-label="delete"
            onClick={() => {
              toggleDeleteDialog(true)
              setSelectedCase(row)
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      )
    },
  ]
  
  return (
    <Box>
      <DataGrid
        className={classes.table}
        rows={closedCases || []}
        columns={columns}
        disableColumnMenu
        autoHeight
      />
      <DeleteDialog
        opened={deleteDialogOpened}
        handleClose={() => {
          setSelectedCase(null)
          toggleDeleteDialog(false)
        }}
        handleDelete={() => {
          deleteCase(selectedCase.caseId)
          toggleDeleteDialog(false)
        }}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    closedCases: state.data.closedCases
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getClosedCases: (userId) => {
      dispatch(getClosedCases(userId))
    },
    deleteCase: (id) => {
      dispatch(deleteCase(id))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClosedCaseList)
