import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import PrivateRoute from './privateRoute'

import Dashboard from '../components/dashboard'
import Case from '../components/case'
import ClosedCases from '../components/closedCases'
import Settings from '../components/tools'
import Login from '../components/login'
import Signup from '../components/signup'
import Users from '../components/users'
import NewUser from '../components/users/new'

function Routes() {
  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/signup' component={Signup} />
      <PrivateRoute exact path='/dashboard'>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute exact path='/case'>
        <Case />
      </PrivateRoute>
      <PrivateRoute exact path='/closed-cases'>
        <ClosedCases />
      </PrivateRoute>
      <PrivateRoute exact path='/case/:caseId'>
        <Case />
      </PrivateRoute>
      <PrivateRoute exact path='/settings'>
        <Settings />
      </PrivateRoute>
      <PrivateRoute exact path='/users'>
        <Users />
      </PrivateRoute>
      <PrivateRoute exact path='/users/new'>
        <NewUser />
      </PrivateRoute>
      <Route
        path='/'
        component={() => <Redirect to={{ pathname: '/dashboard' }} />}
      />
    </Switch>
  )
}

export default withRouter(Routes)
