import * as ActionTypes from '../utils/actionTypes'
import * as ApiServices from '../services/service'
import { notificationMessage } from '../utils/functions'
import {apiLoading, apiFailed} from './loading'

export const logout = (isLoggedIn) => {
  return {
    type: ActionTypes.LOG_OUT,
    payload: isLoggedIn
  }
}

export const authenticate = (data, history) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.authenticate(data)
      .then(response => {
        dispatch({
          type: ActionTypes.LOGIN_SUCCESS,
          payload: response.data
        })
        history.push('/dashboard');
        return Promise.resolve(response.data)
      }).catch(error => {
        notificationMessage('error', error.error)
        dispatch(apiFailed(error))
      })
  }
}

export const register = (data, history) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.register(data)
      .then(response => {
        dispatch({
          type: ActionTypes.SIGNUP_SUCCESS,
          payload: response.data
        })
        history.push('/login');
        return Promise.resolve(response.data)
      }).catch(error => {
        notificationMessage('error', error.error)
        dispatch(apiFailed(error))
      })
  }
}
