import _ from 'lodash'
import * as ActionTypes from '../utils/actionTypes'
import { plfType, dftType, partiesInfoType } from '../utils/const'

const initialState = {
  plaintiffs: [{
    ...plfType
  }],
  defendants: [{
    ...dftType
  }],
  partiesInfo: {
    ...partiesInfoType
  }
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {

    case ActionTypes.CLEAN_CASE_INFO:
      newState.plaintiffs = [{
        ...plfType
      }]
      newState.defendants = [{
        ...dftType
      }]
      newState.partiesInfo = {
        ...partiesInfoType
      }
      return newState

    case ActionTypes.ADD_PARTIES_SUCCESS:
      newState.plaintiffs = action.payload.plaintiffs
      newState.defendants = action.payload.defendants
      newState.partiesInfo = action.payload.partiesInfo
      return newState

    case ActionTypes.GET_PARTIES_SUCCESS: {
      const {plaintiffs, defendants, partiesInfo} = action.payload
      if (plaintiffs.length > 0) {
        newState.plaintiffs = plaintiffs
      } else {
        newState.plaintiffs = [{
          ...plfType
        }]
      }
      if (defendants.length > 0) {
        newState.defendants = defendants
      } else {
        newState.defendants = [{
          ...dftType
        }]
      }
      if (partiesInfo) {
        newState.partiesInfo = partiesInfo
      } else {
        newState.partiesInfo = {
          ...partiesInfoType
        }
      }
      return newState
    }

    default:
      return state
  }
}

export default reducer
