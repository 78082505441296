import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: .5,
    zIndex: 9999,
    background: 'white'
  },
  visible: {
    display: 'none'
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999
  }
}));

export default function LoadingWrapperFull({ visible }) {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${!visible && classes.visible}`}>
      {
        visible && (
          <div className={classes.loaderWrapper}>
            <CircularProgress size={50} />
          </div>
        )
      }
    </div>
  );
}
