import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import DateFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    boxSizing: 'border-box',
  },
}))

const DateSinglePicker = (props) => {
  const classes = useStyles()

  return (
    <FormControl className={`${classes.formControl} ${props.className}`}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          variant="inline"
          // inputVariant="outlined"
          label={props.label || "Date"}
          format="MM/DD/YYYY"
          value={props.value}
          onChange={props.onChange}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  )
}

export default DateSinglePicker
