export const API_LOADING = 'API_LOADING'
export const API_FAILED = 'API_FAILED'

export const LOG_OUT = 'LOG_OUT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'

export const DATA_API_LOADING = 'DATA_API_LOADING'
export const DATA_API_FAILED = 'DATA_API_FAILED'

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'

export const UPLOAD_FORMS_SUCCESS = 'UPLOAD_FORMS_SUCCESS'
export const GET_FORMS_SUCCESS = 'GET_FORMS_SUCCESS'
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS'

export const UPDATE_CASE_INFO = 'UPDATE_CASE_INFO'
export const UPDATE_FINANCIAL_INFO = 'UPDATE_FINANCIAL_INFO'
export const UPDATE_LEDGER_INFO = 'UPDATE_LEDGER_INFO'
export const UPDATE_LEDGER_AMOUNT = 'UPDATE_LEDGER_AMOUNT'
export const CLEAN_CASE_INFO = 'CLEAN_CASE_INFO'

export const GET_ALL_CASE_TYPES_SUCCESS = 'GET_ALL_CASE_TYPES_SUCCESS'
export const GET_ALL_CASE_SUCCESS = 'GET_ALL_CASE_SUCCESS'
export const GET_MY_CASE_SUCCESS = 'GET_MY_CASE_SUCCESS'
export const GET_ALL_CLOSED_CASE_SUCCESS = 'GET_ALL_CLOSED_CASE_SUCCESS'
export const GET_CASE_DETAIL_SUCCESS = 'GET_CASE_DETAIL_SUCCESS'
export const UPDATE_CASE_DETAIL_SUCCESS = 'UPDATE_CASE_DETAIL_SUCCESS'
export const ADD_CASE_SUCCESS = 'ADD_CASE_SUCCESS'
export const DELETE_CASE_SUCCESS = 'DELETE_CASE_SUCCESS'

export const GET_LEDGER_SUCCESS = 'GET_LEDGER_SUCCESS'
export const ADD_LEDGER_SUCCESS = 'ADD_LEDGER_SUCCESS'
export const UPDATE_LEDGER_SUCCESS = 'UPDATE_LEDGER_SUCCESS'

export const GET_PARTIES_SUCCESS = 'GET_PARTIES_SUCCESS'
export const ADD_PARTIES_SUCCESS = 'ADD_PARTIES_SUCCESS'
export const UPDATE_PARTIES_SUCCESS = 'UPDATE_PARTIES_SUCCESS'

export const UPDATE_EVENTS_INFO = 'UPDATE_EVENTS_INFO'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'
export const ADD_EVENTS_SUCCESS = 'ADD_EVENTS_SUCCESS'
export const UPDATE_EVENTS_SUCCESS = 'UPDATE_EVENTS_SUCCESS'

export const UPDATE_PAYOUTS = 'UPDATE_PAYOUTS'
export const UPDATE_PAYOUT_INFO = 'UPDATE_PAYOUT_INFO'
export const GET_PAYOUT_SUCCESS = 'GET_PAYOUT_SUCCESS'
export const ADD_PAYOUT_SUCCESS = 'ADD_PAYOUT_SUCCESS'
export const UPDATE_PAYOUT_SUCCESS = 'UPDATE_PAYOUT_SUCCESS'
