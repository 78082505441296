import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, TextField, Typography, Button, IconButton } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/DeleteOutline'
import { cloneDeep } from 'lodash'

import DatePicker from '../../common/datepicker'
import {
  getParties,
  updateParties,
  addParties
} from '../../../actions/parties'
import { plfType, dftType, partiesInfoType } from '../../../utils/const'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingBottom: spacing(1)
  },
  paper: {
    padding: spacing(2),
    marginBottom: spacing(3)
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: spacing(1),
    marginRight: spacing(1)
  },
  field: {
    width: '22%',
    margin: spacing(1)
  },
  btn: {
    width: spacing(20),
    margin: spacing(1)
  }
}))

const Parties = ({
  caseInfo,
  selectedCaseId,
  plaintiffs: plfs,
  defendants: dfts,
  partiesInfo: pinfo,
  getParties,
  updateParties,
  addParties
}) => {
  const classes = useStyles()
  const [plaintiffs, setPlaintiffs] = useState([])
  const [defendants, setDefendants] = useState([])
  const [partiesInfo, setPartiesInfo] = useState(partiesInfoType)

  useEffect(() => {
    if (selectedCaseId) {
      getParties(selectedCaseId)
    }
  }, [])

  useEffect(() => {
    setPlaintiffs(plfs)
    setDefendants(dfts)
    setPartiesInfo(pinfo)
  }, [plfs, dfts, pinfo])

  const onChangePlaintiff = (id, type, value) => {
    const pfs = cloneDeep(plaintiffs)
    pfs.forEach((p, index) => {
      if (index === id) {
        p[type] = value
      }
    })
    setPlaintiffs(pfs)
  }

  const onChangeDefendant = (id, type, value) => {
    const dfs = cloneDeep(defendants)
    dfs.forEach((p, index) => {
      if (index === id) {
        p[type] = value
      }
    })
    setDefendants(dfs)
  }

  const addItem = (type) => {
    if (type === 'plaintiff') {
      const pfs = cloneDeep(plaintiffs)
      pfs.push(plfType)
      setPlaintiffs(pfs)
    }
    if (type === 'defendant') {
      const dfs = cloneDeep(defendants)
      dfs.push(dftType)
      setDefendants(dfs)
    }
  }

  const removeItem = (type, index) => {
    if (type === 'plaintiff' && plaintiffs.length > 1) {
      const pfs = cloneDeep(plaintiffs)
      pfs.splice(index, 1)
      setPlaintiffs(pfs)
    }
    if (type === 'defendant' && defendants.length > 1) {
      const dfs = cloneDeep(defendants)
      dfs.splice(index, 1)
      setDefendants(dfs)
    }
  }

  const onSave = () => {
    console.log('-- ', partiesInfo)
    if (selectedCaseId) {
      updateParties(selectedCaseId, { plaintiffs, defendants, partiesInfo })
    } else {
      addParties(caseInfo.caseId, { plaintiffs, defendants, partiesInfo })
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Plaintiff(s) Information</Typography>
        {
          plaintiffs.map((p, index) => (
            <Box display="flex" flexWrap="wrap" alignItems="flex-end" key={index}>
              <TextField className={classes.field} label="Name" value={p.name} onChange={(e) => onChangePlaintiff(index, 'name', e.target.value)} />
              <TextField className={classes.field} label="Email" value={p.email} onChange={(e) => onChangePlaintiff(index, 'email', e.target.value)} />
              <TextField className={classes.field} label="Street1" value={p.street1} onChange={(e) => onChangePlaintiff(index, 'street1', e.target.value)} />
              <TextField className={classes.field} label="Street2" value={p.street2} onChange={(e) => onChangePlaintiff(index, 'street2', e.target.value)} />
              <TextField className={classes.field} label="City" value={p.city} onChange={(e) => onChangePlaintiff(index, 'city', e.target.value)} />
              <TextField className={classes.field} label="State" value={p.state} onChange={(e) => onChangePlaintiff(index, 'state', e.target.value)} />
              <TextField className={classes.field} label="Zip" value={p.zip} onChange={(e) => onChangePlaintiff(index, 'zip', e.target.value)} />
              <TextField className={classes.field} label="Phone Number" value={p.phone} onChange={(e) => onChangePlaintiff(index, 'phone', e.target.value)} />
              <IconButton onClick={() => removeItem('plaintiff', index)}><RemoveIcon /></IconButton>
            </Box>
          ))
        }
        <Box display="flex" justifyContent="center" alignItems="flex-end">
          <Button className={classes.btn} color="primary" variant="contained" onClick={() => addItem('plaintiff')}>Add</Button>
        </Box>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Defendant(s) Information</Typography>
        {
          defendants.map((d, index) => (
            <Box display="flex" flexWrap="wrap" alignItems="flex-end" key={index}>
              <TextField className={classes.field} label="Name" value={d.name} onChange={(e) => onChangeDefendant(index, 'name', e.target.value)} />
              <TextField className={classes.field} label="Email" value={d.email} onChange={(e) => onChangeDefendant(index, 'email', e.target.value)} />
              <TextField className={classes.field} label="Street1" value={d.street1} onChange={(e) => onChangeDefendant(index, 'street1', e.target.value)} />
              <TextField className={classes.field} label="Street2" value={d.street2} onChange={(e) => onChangeDefendant(index, 'street2', e.target.value)} />
              <TextField className={classes.field} label="City" value={d.city} onChange={(e) => onChangeDefendant(index, 'city', e.target.value)} />
              <TextField className={classes.field} label="State" value={d.state} onChange={(e) => onChangeDefendant(index, 'state', e.target.value)} />
              <TextField className={classes.field} label="Zip" value={d.zip} onChange={(e) => onChangeDefendant(index, 'zip', e.target.value)} />
              <TextField className={classes.field} label="Phone Number" value={d.phone} onChange={(e) => onChangeDefendant(index, 'phone', e.target.value)} />
              <TextField className={classes.field} label="Social Security Number" value={d.socialSecurity} onChange={(e) => onChangeDefendant(index, 'socialSecurity', e.target.value)} />
              <DatePicker className={classes.field} label="Date of birth" value={d.dob} onChange={(value) => onChangeDefendant(index, 'dob', value)} />
              <IconButton onClick={() => removeItem('defendant', index)}><RemoveIcon /></IconButton>
            </Box>
          ))
        }
        <Box display="flex" justifyContent="center" alignItems="flex-end">
          <Button className={classes.btn} color="primary" variant="contained" onClick={() => addItem('defendant')}>Add</Button>
        </Box>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">General Comments</Typography>
        <Box>
          <TextField
            style={{marginTop: 16}}
            label=""
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            value={partiesInfo.comments} 
            onChange={(e) => setPartiesInfo({comments: e.target.value})}
          />
        </Box>
      </Paper>
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.field} color="primary" variant="contained" onClick={onSave}>Save</Button>
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    caseInfo: state.data.caseInfo,
    plaintiffs: state.parties.plaintiffs,
    defendants: state.parties.defendants,
    partiesInfo: state.parties.partiesInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getParties: (caseId) => {
      dispatch(getParties(caseId))
    },
    addParties: (caseId, data) => {
      dispatch(addParties(caseId, data))
    },
    updateParties: (caseId, data) => {
      dispatch(updateParties(caseId, data))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Parties)
