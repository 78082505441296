import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))

export default function SimpleSelect({ className, label, value, onChange, options, variant = 'standard', ...props}) {
  const classes = useStyles()

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl className={`${classes.formControl} ${className}`} variant={variant}>
      <InputLabel>{label || 'Select'}</InputLabel>
      <Select
        label={label}
        value={value}
        onChange={handleChange}
        {...props}
      >
        {
          options.map((option, index) => (
            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}
