import _ from 'lodash'
import * as ActionTypes from '../utils/actionTypes'
import { payoutType, payoutInfoType } from '../utils/const'

const initialState = {
  payouts: [{
    ...payoutType
  }],
  payoutInfo: {
    ...payoutInfoType
  }
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.CLEAN_CASE_INFO:
      newState.payouts = [{
        ...payoutType
      }]
      newState.payoutInfo = {
        ...payoutInfoType
      }
      return newState

    case ActionTypes.UPDATE_PAYOUTS:
      newState.payouts = action.payload
      return newState

    case ActionTypes.UPDATE_PAYOUT_INFO:
      newState.payoutInfo = action.payload
      return newState

    case ActionTypes.ADD_PAYOUT_SUCCESS:
      newState.payouts = action.payload.payouts
      newState.payoutInfo = action.payload.payoutInfo
      return newState

    case ActionTypes.GET_PAYOUT_SUCCESS:
      const {payouts, payoutInfo} = action.payload
      if (payouts.length > 0) {
        newState.payouts = payouts
      } else {
        newState.payouts = [{
          ...payoutType
        }]
      }
      if (payoutInfo.length > 0) {
        newState.payoutInfo = payoutInfo[0]
      } else {
        newState.payoutInfo = {
          ...payoutInfoType
        }
      }
      return newState

    default:
      return state
  }
}

export default reducer
