import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ children, isLoggedIn, ...props }) => {

  // if (isLoggedIn === null) {
  //   return (
  //     <div className='private-loader-container'>
  //       <Loader className='private-loader' width={200} height={200} />
  //     </div>
  //   )
  // }

  return (
    <Route
      {...props}
      render={({ location }) =>
      isLoggedIn
          ? children
          : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
            )}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

export default connect(mapStateToProps, null)(PrivateRoute)
