import _ from 'lodash'
import * as ActionTypes from '../utils/actionTypes'
import {
  validUntilDateFormula,
  startingAmountFormula,
  totalInterestFormula1
} from '../utils/formula'


const caseInfoType = {
  caseNumber: '',
  caseType: '',
  entryDate: new Date(),
  caseState: '',
  interest: 9,
  validityYears: '',
  startDate: null,
  priorAmount: '',
  caseStatus: 'open'
}
const financialInfoType = {
  judgement: '',
  feeBefore: '',
  feeAfter: '',
  totalRecovered: '',
  totalDue: '',
  progressRate: ''
}
const ledgerInfoType = {
  startDate: null,
  endDate: null,
  days: '',
  description: '',
  recovery: 0,
  principal: '',
  interest: ''
}
const ledgerAmountType = {
  recoveredTotal: '',
  recoveredPrincipal: '',
  recoveredInterest: '',
  dueTotal: '',
  duePrincipal: '',
  dueInterest: '',
  costAdvanced: ''
}

const initialState = {
  allCases: null,
  caseTypes: null,
  cases: null,
  closedCases: null,
  caseDetail: null,
  caseInfo: {
    ...caseInfoType
  },
  financialInfo: {
    ...financialInfoType
  },
  ledgerInfo: [{
    ...ledgerInfoType
  }],
  ledgerAmount: {
    ...ledgerAmountType
  }
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {

    case ActionTypes.UPDATE_CASE_INFO: {
      newState.caseInfo = action.payload
      if (newState.caseInfo.caseStatus === 'close') {
        if (newState.allCases) {
          newState.allCases = newState.allCases.filter(c => c.caseId !== action.payload.caseId)
        }
        if (newState.cases) {
          newState.cases = newState.cases.filter(c => c.caseId !== action.payload.caseId)
        }
        if (newState.closedCases) {
          newState.closedCases.push(action.payload)
        }
      }
      // update ledger
      const { startDate, validityYears, priorAmount, interest} = action.payload
      const { judgement } = newState.financialInfo
      if (startDate) {
        newState.ledgerInfo[0].startDate = startDate
      }
      if (validityYears && startDate) {
        const validUntilDate = validUntilDateFormula(validityYears, startDate)
        newState.ledgerInfo[0].endDate = validUntilDate
      }
      if (priorAmount && judgement) {
        const pAmount = startingAmountFormula(judgement, priorAmount)
        newState.ledgerInfo[0].principal = pAmount
      }
      if (priorAmount && judgement && interest && validityYears) {
        const totalInterest = totalInterestFormula1(judgement, priorAmount, interest, validityYears)
        newState.ledgerInfo[0].interest = totalInterest
      }
      return newState
    }

    case ActionTypes.UPDATE_FINANCIAL_INFO: {
      newState.financialInfo = action.payload
      // update ledger
      const { judgement } = action.payload
      const { priorAmount, interest, validityYears } = newState.caseInfo
      if (judgement && priorAmount) {
        const pAmount = startingAmountFormula(judgement, priorAmount)
        newState.ledgerInfo[0].principal = pAmount
      }
      if (priorAmount && judgement && interest && validityYears) {
        const totalInterest = totalInterestFormula1(judgement, priorAmount, interest, validityYears)
        newState.ledgerInfo[0].interest = totalInterest
      }
      return newState
    }

    case ActionTypes.UPDATE_LEDGER_INFO:
      newState.ledgerInfo = action.payload
      return newState

    case ActionTypes.UPDATE_LEDGER_AMOUNT:
      newState.ledgerAmount = action.payload
      return newState

    case ActionTypes.CLEAN_CASE_INFO:
      newState.caseInfo = {
        ...caseInfoType
      }
      newState.financialInfo = {
        ...financialInfoType
      }
      return newState

    case ActionTypes.GET_ALL_CASE_TYPES_SUCCESS:
      newState.caseTypes = action.payload
      return newState

    case ActionTypes.GET_MY_CASE_SUCCESS:
      newState.cases = action.payload
      return newState

    case ActionTypes.GET_ALL_CASE_SUCCESS:
      newState.allCases = action.payload
      return newState

    case ActionTypes.GET_ALL_CLOSED_CASE_SUCCESS:
      newState.closedCases = action.payload
      return newState

    case ActionTypes.GET_CASE_DETAIL_SUCCESS:
      newState.caseDetail = action.payload
      newState.caseInfo = action.payload
      newState.financialInfo = {
        judgement: action.payload.judgement,
        feeBefore: action.payload.feeBefore,
        feeAfter: action.payload.feeAfter,
        totalRecovered: action.payload.totalRecovered,
        totalDue: action.payload.totalDue,
        progressRate: action.payload.progressRate
      }
      return newState

    case ActionTypes.UPDATE_CASE_DETAIL_SUCCESS:
      newState.cases = newState.cases.map(c => c.caseId === action.payload.caseId ? {...c, ...action.payload} : c);
      newState.caseDetail = {...newState.caseDetail, ...action.payload};
      return newState

    case ActionTypes.UPDATE_LEDGER_SUCCESS:
      newState.cases = newState.cases.map(c => c.caseId === action.payload.caseId ? {...c, ledgerAmount: [action.payload.ledgerAmount]} : c);
      return newState

    case ActionTypes.UPDATE_PARTIES_SUCCESS:
      newState.cases = newState.cases.map(c => c.caseId === action.payload.caseId ? {...c, plaintiffs: action.payload.plaintiffs, defendants: action.payload.defendants} : c);
      return newState

    case ActionTypes.ADD_CASE_SUCCESS:
      if (newState.cases === null) {
        newState.cases = []
      }
      newState.cases.push(action.payload)
      newState.caseInfo.caseId = action.payload.caseId
      return newState

    case ActionTypes.DELETE_CASE_SUCCESS:
      if (newState.cases) {
        newState.cases = newState.cases.filter(c => c.caseId !== action.payload.caseId)
      }
      if (newState.closedCases) {
        newState.closedCases = newState.closedCases.filter(c => c.caseId !== action.payload.caseId)
      }
      if (newState.allCases) {
        newState.allCases = newState.allCases.filter(c => c.caseId !== action.payload.caseId)
      }
      return newState

    case ActionTypes.GET_LEDGER_SUCCESS:
      if (action.payload.ledgers.length > 0) {
        newState.ledgerInfo = action.payload.ledgers
      } else {
        newState.ledgerInfo = [{
          ...ledgerInfoType
        }]
      }
      if (action.payload.ledgerAmount.length > 0) {
        newState.ledgerAmount = action.payload.ledgerAmount[0]
      } else {
        newState.ledgerAmount = {
          ...ledgerAmountType
        }
      }
      return newState

    case ActionTypes.ADD_LEDGER_SUCCESS:
      newState.ledgerInfo = action.payload.ledgerInfo
      newState.ledgerAmount = action.payload.ledgerAmount
      return newState

    case ActionTypes.LOG_OUT:
      newState = initialState
      return newState

    default:
      return state
  }
}

export default reducer
