import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, TextField, Typography, Button } from '@material-ui/core'

import DatePicker from '../../common/datepicker'
import Select from '../../common/select'
import DefaultCurrencyField from '../../common/defaultCurrencyField'
import CustomCurrencyField from '../../common/customCurrencyField'
import Autocomplete from '../../common/autocomplete'
import FreeSoloCreateOption from '../../common/autoCompleteWithFreeSolo'
import { states, caseStatus } from '../../../utils/const'

import { updateCaseInfo, updateFinancialInfo, addCase, updateCase, getAllCaseTypes } from '../../../actions/data'
import {
  validUntilDateFormula,
  startingAmountFormula,
  totalValueFormula,
  totalInterestFormula,
  dailyInterestFormula
} from '../../../utils/formula'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingBottom: spacing(1)
  },
  paper: {
    padding: spacing(2),
    marginBottom: spacing(3)
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: spacing(1),
    marginRight: spacing(1)
  },
  field: {
    width: '18%',
    margin: spacing(1)
  }
}))

const Main = ({
  user,
  cases,
  caseInfo,
  financialInfo,
  updateCaseInfo,
  updateFinancialInfo,
  addCase,
  updateCase,
  caseTypes,
  getAllCaseTypes,
  selectedCaseId
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (caseTypes === null) {
      getAllCaseTypes()
    }
  }, [])

  const onChangeCaseInfo = (type, value) => {
    updateCaseInfo({...caseInfo, [type]: value})
  }
  const onChangeFinancialInfo = (type, value) => {
    updateFinancialInfo({...financialInfo, [type]: value})
  }
  const onSave = () => {
    const caseType = caseInfo.caseType ? caseInfo.caseType.value : ''
    const caseData = {...caseInfo, ...financialInfo, caseType}
    if (selectedCaseId) {
      updateCase(selectedCaseId, {...caseData})
    } else if (caseInfo && caseInfo.caseId) {
      updateCase(caseInfo.caseId, {...caseData})
    } else {
      addCase({...caseData, userId: user.userId})
    }
  }
  const validUntilDate = validUntilDateFormula(caseInfo.validityYears, caseInfo.startDate)
  const startingAmount = startingAmountFormula(financialInfo.judgement, caseInfo.priorAmount)
  const totalValue = totalValueFormula(caseInfo.interest, caseInfo.validityYears, startingAmount)
  const totalInterest = totalInterestFormula(totalValue, startingAmount)
  const avgDailyInterest = dailyInterestFormula(totalInterest, caseInfo.validityYears)
  let caseTypeOptions = []
  if (caseTypes) {
    caseTypeOptions = caseTypes.filter(el => el).map((d) => ({label: d, value: d}))
  }
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box display="flex" alignItems="center">
          <Typography className={classes.subtitle} variant="subtitle1">Case Information</Typography>
        </Box>
        <Box>
          <TextField className={classes.field} label="Case Number" 
            value={caseInfo.caseNumber} onChange={(e) => onChangeCaseInfo('caseNumber', e.target.value)} 
          />
          {/* <TextField className={classes.field} label="Case Type"
            value={caseInfo.caseType} onChange={(e) => onChangeCaseInfo('caseType', e.target.value)}
          /> */}
          <FreeSoloCreateOption
            className={classes.field}
            label="Case Type"
            value={caseInfo.caseType}
            setValue={(value) => onChangeCaseInfo('caseType', value)}
            options={caseTypeOptions}
          />
          <DatePicker className={classes.field} label="DB Entry Date"
            value={caseInfo.createdAt} onChange={(value) => onChangeCaseInfo('entryDate', value)}
          />
          <Select className={classes.field} label="Case State" options={states}
            value={caseInfo.caseState} onChange={(value) => onChangeCaseInfo('caseState', value)}
          />
          <TextField className={classes.field} label="Interest %" type="number"
            value={caseInfo.interest} onChange={(e) => onChangeCaseInfo('interest', e.target.value)}
          />
          <TextField className={classes.field} label="Validity in Years" type="number" 
            value={caseInfo.validityYears} onChange={(e) => onChangeCaseInfo('validityYears', e.target.value)}
          />
          <DatePicker className={classes.field} label="Interest Starting Date"
            value={caseInfo.startDate} onChange={(value) => onChangeCaseInfo('startDate', value)}
          />
          <TextField className={classes.field} label="Valid Until" disabled 
            value={validUntilDate}
          />
          <CustomCurrencyField
            className={classes.field}
            label="Recov. Prior to Assign"
            value={caseInfo.priorAmount}
            onChange={(e, value) => onChangeCaseInfo('priorAmount', value)} 
            min='0'
          />
        </Box>
      </Paper>

      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Financial Information</Typography>
        <Box>
          <CustomCurrencyField
            className={classes.field}
            label="Judgement Amount"
            value={financialInfo.judgement}
            onChange={(e, value) => onChangeFinancialInfo('judgement', value)}
          />
          <TextField className={classes.field} type="number" label="Fees before interest"
            value={financialInfo.feeBefore} onChange={(e) => onChangeFinancialInfo('feeBefore', e.target.value)}
          />
          <TextField className={classes.field} type="number" label="Fees after interest"
            value={financialInfo.feeAfter} onChange={(e) => onChangeFinancialInfo('feeAfter', e.target.value)}
          />
        </Box>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Financial Information Cont'd</Typography>
        <Box>
          <DefaultCurrencyField
            className={classes.field}
            label="Starting Amount"
            disabled
            value={Number(startingAmount).toFixed(2)}
          />
          <TextField className={classes.field} label="Number of Days" disabled
            value={(caseInfo.startDate && Math.abs(moment().diff(moment(caseInfo.startDate), 'days'))) || ''}
          />
          <TextField className={classes.field} label="Avg. Daily Interest" disabled value={avgDailyInterest.toFixed(2)}/>
        </Box>
        <Typography className={classes.subtitle} variant="subtitle1">Recovery Status Today</Typography>
        <Box>
          <TextField className={classes.field} label="Total Recovered" type="number"
            value={financialInfo.totalRecovered} onChange={(e) => onChangeFinancialInfo('totalRecovered', e.target.value)}
          />
          <TextField className={classes.field} label="Total Due" type="number"
            value={financialInfo.totalDue} onChange={(e) => onChangeFinancialInfo('totalDue', e.target.value)}
          />
          <TextField className={classes.field} label="Progress Rate" type="number"
            value={financialInfo.progressRate} onChange={(e) => onChangeFinancialInfo('progressRate', e.target.value)}
          />
        </Box>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Case Status</Typography>
        <Box display="flex" alignItems="flex-end">
          <Select 
            className={classes.field}
            label="Case Status"
            options={caseStatus}
            value={caseInfo.caseStatus}
            onChange={(value) => onChangeCaseInfo('caseStatus', value)}
          />
          <TextField className={classes.field} label="On Date" disabled 
            value={(moment().format('MM/DD/YYYY')) || ''}
          />
          <Button className={classes.field} color="primary" variant="contained">Status Progress Report</Button>
        </Box>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.subtitle} variant="subtitle1">Case Total Value:</Typography>
        {totalValue && '$' + totalValue.toFixed(2)}
        <Box>
          <TextField
            style={{marginTop: 16}}
            label="Case History"
            fullWidth
            multiline
            rows={5}
            variant="outlined"
          />
        </Box>
      </Paper>
      <Box display="flex" justifyContent="flex-end">
        <Button
          className={classes.field}
          color="primary"
          variant="contained"
          onClick={onSave}
        >Save</Button>
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    caseInfo: state.data.caseInfo,
    financialInfo: state.data.financialInfo,
    cases: state.data.cases,
    caseTypes: state.data.caseTypes,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCaseInfo: (data) => {
      dispatch(updateCaseInfo(data))
    },
    updateFinancialInfo: (data) => {
      dispatch(updateFinancialInfo(data))
    },
    addCase: (data) => {
      return dispatch(addCase(data))
    },
    updateCase: (id, data) => {
      return dispatch(updateCase(id, data))
    },
    getAllCaseTypes: () => {
      return dispatch(getAllCaseTypes())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main)
