import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import LoadingWrapper from './components/common/loadingWrapperFull'
import AppBar from './layouts/appBar'
import Drawer from './layouts/drawer'
import Routes from './layouts/routes'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    overflow: 'auto', 
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#F8F8F8'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

function AppContent({ isLoggedIn, isLoading }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <BrowserRouter>
      <LoadingWrapper visible={isLoading} />
      {
        isLoggedIn && (
          <>
            <AppBar drawerOpened={open} handleDrawerOpen={handleDrawerOpen} />
            <Drawer drawerOpened={open} handleDrawerClose={handleDrawerClose} />
          </>
        )
      }
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Routes />
      </main>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading.isLoading,
    isLoggedIn: state.auth.isLoggedIn
  }
}

export default connect(
  mapStateToProps,
  null
)(AppContent)
