import * as ActionTypes from '../utils/actionTypes'
import * as ApiServices from '../services/service'
import {apiLoading, apiFailed} from './loading'
import { notificationMessage } from '../utils/functions'

export const cleanCaseInfo = () => {
  return {
    type: ActionTypes.CLEAN_CASE_INFO
  }
}

export const updateCaseInfo = (data) => {
  return {
    type: ActionTypes.UPDATE_CASE_INFO,
    payload: data
  }
}

export const updateFinancialInfo = (data) => {
  return {
    type: ActionTypes.UPDATE_FINANCIAL_INFO,
    payload: data
  }
}

export const updateLedgerInfo = (data) => {
  return {
    type: ActionTypes.UPDATE_LEDGER_INFO,
    payload: data
  }
}

export const updateLedgerAmount = (data) => {
  return {
    type: ActionTypes.UPDATE_LEDGER_AMOUNT,
    payload: data
  }
}

export const getAllCases = () => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getCases('open')
      .then(response => {
        dispatch({
          type: ActionTypes.GET_ALL_CASE_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        // if (error.status === 'error') notificationMessage('error', error.message)
        dispatch(apiFailed(error))
      })
  }
}

export const getAllCaseTypes = () => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getCaseTypes()
      .then(response => {
        dispatch({
          type: ActionTypes.GET_ALL_CASE_TYPES_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        // if (error.status === 'error') notificationMessage('error', error.message)
        dispatch(apiFailed(error))
      })
  }
}

export const getCases = (userId = null) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getCases('open', userId)
      .then(response => {
        dispatch({
          type: ActionTypes.GET_MY_CASE_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        // if (error.status === 'error') notificationMessage('error', error.message)
        dispatch(apiFailed(error))
      })
  }
}

export const getClosedCases = (userId = null) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getCases('close', userId)
      .then(response => {
        dispatch({
          type: ActionTypes.GET_ALL_CLOSED_CASE_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        // if (error.status === 'error') notificationMessage('error', error.message)
        dispatch(apiFailed(error))
      })
  }
}

export const getCaseDetail = (caseId) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getCaseDetail(caseId)
      .then(response => {
        dispatch({
          type: ActionTypes.GET_CASE_DETAIL_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        // if (error.status === 'error') notificationMessage('error', error.message)
        dispatch(apiFailed(error))
      })
  }
}

export const updateCase = (caseId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.updateCase(caseId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_CASE_DETAIL_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        // if (error.status === 'error') notificationMessage('error', error.message)
        dispatch(apiFailed(error))
      })
  }
}

export const addCase = (data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.addCase(data)
      .then(response => {
        dispatch({
          type: ActionTypes.ADD_CASE_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const deleteCase = (caseId) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.deleteCase(caseId)
      .then(response => {
        dispatch({
          type: ActionTypes.DELETE_CASE_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        notificationMessage('error', 'Failed in deleting a case. Please retry')
        dispatch(apiFailed(error))
      })
  }
}

export const getLedger = (caseId) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getLedger(caseId)
      .then(response => {
        dispatch({
          type: ActionTypes.GET_LEDGER_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const updateLedger = (caseId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.updateLedger(caseId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_LEDGER_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const addLedger = (caseId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.addLedger(caseId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.ADD_LEDGER_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}
