import * as ActionTypes from '../utils/actionTypes'
import * as ApiServices from '../services/service'
import { notificationMessage } from '../utils/functions'
import {apiLoading, apiFailed} from './loading'
import {logout} from './auth'

export const getUsers = () => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getUsers()
      .then(response => {
        dispatch({
          type: ActionTypes.GET_USERS_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const addUser = (data, history) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.register(data)
      .then(response => {
        dispatch({
          type: ActionTypes.ADD_USER_SUCCESS,
          payload: response.data
        })
        history.push('/users');
        return Promise.resolve(response.data)
      }).catch(error => {
        notificationMessage('error', error.error)
        dispatch(apiFailed(error))
      })
  }
}

export const changePassword = (userId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.changePassword(userId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.RESET_PASSWORD_SUCCESS,
          payload: response.data
        })
        notificationMessage('success', 'Password has been changed successfully. Please login again')
        dispatch(logout())
      }).catch(error => {
        notificationMessage('error', error.error)
        dispatch(apiFailed(error))
      })
  }
}

export const resetPassword = (userId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.resetPassword(userId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.RESET_PASSWORD_SUCCESS,
          payload: response.data
        })
        notificationMessage('success', 'Affiliate password has been changed successfully')
      }).catch(error => {
        notificationMessage('error', error.error)
        dispatch(apiFailed(error))
      })
  }
}

export const deleteUser = (userId) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.deleteUser(userId)
      .then(response => {
        dispatch({
          type: ActionTypes.DELETE_USER_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const updateUser = (userId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.updateUser(userId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_USER_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}
