import React, { useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import AllCases from './allCases'
import MyCases from './myCases'

import { isAdmin } from '../../utils/const'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}))

function Dashboard({user}) {
  const classes = useStyles()
  const [value, setValue] = useState('my')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  
  return (
    <div className={classes.root}>
      {isAdmin(user) ? (
        <TabContext value={value}>
          <AppBar position="static" color="default">
            <TabList onChange={handleChange} aria-label="tabs" indicatorColor="primary" textColor="primary">
              <Tab label="My Cases" value="my" />
              <Tab label="All Cases" value="all" />
            </TabList>
          </AppBar>
          <TabPanel value='my'>
            <MyCases />
          </TabPanel>
          <TabPanel value='all'>
            <AllCases />
          </TabPanel>
        </TabContext>
      ) : (
        <TabContext value={value}>
          <AppBar position="static" color="default">
            <TabList onChange={handleChange} aria-label="tabs" indicatorColor="primary" textColor="primary">
              <Tab label="My Cases" value="my" />
            </TabList>
          </AppBar>
          <TabPanel value='my'>
            <MyCases />
          </TabPanel>
        </TabContext>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
