import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, TextField, Typography, Button } from '@material-ui/core'

import { addUser } from '../../actions/users'

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    padding: spacing(2)
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: spacing(1),
    marginRight: spacing(1)
  },
  field: {
    width: '31%',
    minWidth: 300,
    margin: spacing(1)
  },
  signInBtn: {
    width: spacing(25),
    marginTop: spacing(3),
    marginBottom: spacing(2),
    marginLeft: 'auto'
  },
}))

function AddNewUser({ addUser }) {
  const classes = useStyles()
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    clientCompany: '',
    clientName: '',
    address: ''
  })

  const onAdd = () => {
    if (state.name !== '' && state.email !== '' && state.password !== '') {
      addUser(state)
    }
  }

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.subtitle} variant="subtitle1">Add a Affiliate</Typography>
      <Box>
        <TextField
          className={classes.field}
          label="Client Company Name"
          value={state.clientCompany}
          onChange={e => setState({...state, clientCompany: e.target.value})}
        />
        <TextField
          className={classes.field}
          label="Client Name"
          value={state.clientName}
          onChange={e => setState({...state, clientName: e.target.value})}
        />
        <TextField
          className={classes.field}
          label="Address"
          value={state.address}
          onChange={e => setState({...state, address: e.target.value})}
        />
        <TextField
          className={classes.field}
          required
          label="Username"
          value={state.name}
          onChange={e => setState({...state, name: e.target.value})}
        />
        <TextField
          className={classes.field}
          required
          label="Email Address"
          value={state.email}
          onChange={e => setState({...state, email: e.target.value})}
        />
        <TextField
          className={classes.field}
          required
          name="password"
          label="Password"
          type="password"
          id="password"
          value={state.password}
          onChange={e => setState({...state, password: e.target.value})}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <Button className={classes.signInBtn} variant="contained" color="primary" onClick={onAdd} disabled={!state.name || !state.email || !state.password}>
          Add
        </Button>
      </Box>
    </Paper>
  )
}

const mapStateToProps = (state) => {
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addUser: (data) => {
      dispatch(addUser(data, ownProps.history))
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
  (AddNewUser)
)
