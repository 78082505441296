import * as ActionTypes from '../utils/actionTypes'
import * as ApiServices from '../services/service'
import {apiLoading, apiFailed} from './loading'

export const updatePayouts = (data) => {
  return {
    type: ActionTypes.UPDATE_PAYOUTS,
    payload: data
  }
}

export const updatePayoutInfo = (data) => {
  return {
    type: ActionTypes.UPDATE_PAYOUT_INFO,
    payload: data
  }
}

export const getPayout = (caseId) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.getPayout(caseId)
      .then(response => {
        dispatch({
          type: ActionTypes.GET_PAYOUT_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const updatePayout = (caseId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.updatePayout(caseId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_PAYOUT_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}

export const addPayout = (caseId, data) => {
  return (dispatch, getState) => {
    dispatch(apiLoading())
    return ApiServices.addPayout(caseId, data)
      .then(response => {
        dispatch({
          type: ActionTypes.ADD_PAYOUT_SUCCESS,
          payload: response.data
        })
        return Promise.resolve(response.data)
      }).catch(error => {
        dispatch(apiFailed(error))
      })
  }
}
