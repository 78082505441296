import _ from 'lodash'
import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  users: null,
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {

    case ActionTypes.GET_USERS_SUCCESS:
      newState.users = action.payload
      return newState

    case ActionTypes.ADD_USER_SUCCESS:
      newState.users.push(action.payload)
      return newState

    case ActionTypes.DELETE_USER_SUCCESS:
      newState.users = newState.users.filter(u => u.userId !== action.payload.userId)
      return newState

    case ActionTypes.UPDATE_USER_SUCCESS:
      newState.users = newState.users.map(c => c.userId === action.payload.userId ? {...c, ...action.payload} : c);
      return newState
      

    default:
      return state
  }
}

export default reducer
