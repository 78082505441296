import React from 'react'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const CustomCurrencyField = ({
  className,
  label,
  value,
  onChange,
  min
}) => {
  
  return (
    <CurrencyTextField
      className={className}
      label={label}
      unselectable
      currencySymbol="$"
      value={value}
      onChange={onChange}
      minimumValue={min}
    />
  )
}

export default CustomCurrencyField
