import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.API_LOADING:
      newState.isLoading = true
      return newState

    case ActionTypes.API_FAILED:
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.SIGNUP_SUCCESS:
    case ActionTypes.GET_ALL_CASE_TYPES_SUCCESS:
    case ActionTypes.GET_MY_CASE_SUCCESS:
    case ActionTypes.GET_ALL_CASE_SUCCESS:
    case ActionTypes.GET_ALL_CLOSED_CASE_SUCCESS:
    case ActionTypes.GET_CASE_DETAIL_SUCCESS:
    case ActionTypes.UPDATE_LEDGER_SUCCESS:
    case ActionTypes.UPDATE_CASE_DETAIL_SUCCESS:
    case ActionTypes.ADD_CASE_SUCCESS:
    case ActionTypes.DELETE_CASE_SUCCESS:
    case ActionTypes.GET_LEDGER_SUCCESS:
    case ActionTypes.ADD_LEDGER_SUCCESS:
    case ActionTypes.GET_EVENTS_SUCCESS:
    case ActionTypes.UPDATE_EVENTS_SUCCESS:
    case ActionTypes.ADD_EVENTS_SUCCESS:
    case ActionTypes.GET_PARTIES_SUCCESS:
    case ActionTypes.UPDATE_PARTIES_SUCCESS:
    case ActionTypes.ADD_PARTIES_SUCCESS:
    case ActionTypes.GET_PAYOUT_SUCCESS:
    case ActionTypes.UPDATE_PAYOUT_SUCCESS:
    case ActionTypes.ADD_PAYOUT_SUCCESS:
    case ActionTypes.GET_USERS_SUCCESS:
    case ActionTypes.ADD_USER_SUCCESS:
    case ActionTypes.DELETE_USER_SUCCESS:
    case ActionTypes.UPDATE_USER_SUCCESS:
    case ActionTypes.GET_FORMS_SUCCESS:
    case ActionTypes.UPLOAD_FORMS_SUCCESS:
    case ActionTypes.DELETE_FORM_SUCCESS:
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      newState.isLoading = false
      return newState

    default:
      return state
  }
}

export default reducer
