import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, Typography, IconButton, Checkbox, Button } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'

import FormsDialog from '../../dashboard/selectForms'
import { getForms, deleteForm } from '../../../actions/forms'
import { updateCase } from '../../../actions/data'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingBottom: spacing(1)
  },
  paper: {
    padding: spacing(2),
    marginBottom: spacing(3)
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: spacing(1),
    marginRight: spacing(1)
  },
  noform: {
    textAlign: 'center'
  },
  fileName: {
    marginRight: spacing(3),
    marginLeft: spacing(3)
  },
  saveBtn: {
    flex: 1,
    maxWidth: 300,
    margin: spacing(1)
  },
}))

const Forms = ({
  caseDetail,
  updateCase,
  getForms,
  deleteForm,
  forms
}) => {
  const classes = useStyles()
  const { REACT_APP_S3_BUCKET_URL } = process.env
  const [selectedUFormsIds, setSelectedUFormIds] = useState([])
  const [formsDialogOpened, toggleFormsDialog] = useState(false)
  
  useEffect(() => {
    if(caseDetail && forms) {
      setSelectedUFormIds(caseDetail.forms)
    }
  }, [caseDetail, forms])

  useEffect(() => {
    if(forms === null) {
      getForms()
    }
  }, [])

  const handleChange = (e, formId) => {
    const selecteds = [...selectedUFormsIds]
    if (e.target.checked) {
      selecteds.push(formId)
    } else {
      const index = selecteds.indexOf(formId);
      if (index > -1) {
        selecteds.splice(index, 1);
      }
    }
    setSelectedUFormIds(selecteds)
  };

  const handleConfirm = () => {
    updateCase(caseDetail.caseId, {forms: selectedUFormsIds});
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {
          forms !== null && forms.length > 0 ? (
            <>
              <Typography className={classes.subtitle} variant="subtitle1">Uploaded Forms</Typography>
              {
                forms.map(form => {
                  if (caseDetail.caseId === form.caseId) {
                    return (
                      <Box display="flex" alignItems="center" my={2} key={form.formId}>
                        {/* <Checkbox
                          checked={selectedUFormsIds.indexOf(form.formId) !== -1}
                          onChange={(e) => handleChange(e, form.formId)}
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        /> */}
                        <Typography 
                          className={classes.fileName} 
                          key={form.formId}
                          variant="body2"
                        >
                          {form.name}
                        </Typography>
                        <IconButton onClick={() => window.open(REACT_APP_S3_BUCKET_URL + form.fromKey, '_blank')}>
                          <OpenInNewIcon fontSize="small" />
                        </IconButton>
                        <Box mx={2}>
                          <IconButton onClick={() => deleteForm(form.formId)}>
                            <DeleteOutlined fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    )
                  }
                }) 
              }
            </>
          ) : (
            <Typography className={classes.noform}>There are no forms uploaded</Typography>
          )
        }
      </Paper>
      <Box display="flex" justifyContent="flex-end">
        <Button 
          className={classes.saveBtn} 
          variant="outlined" 
          size="small" 
          onClick={() => toggleFormsDialog(true)}
        >
          Upload Forms
        </Button>
        {
          forms !== null && forms.length > 0 && (
            <Button className={classes.saveBtn} color="primary" variant="contained" onClick={handleConfirm}>
              Save
            </Button>
          )
        }
      </Box>
      <FormsDialog
        opened={formsDialogOpened}
        selectedCase={caseDetail}
        handleClose={() => toggleFormsDialog(false)}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    caseDetail: state.data.caseDetail,
    forms: state.forms.forms
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (id, data) => {
      return dispatch(updateCase(id, data))
    },
    getForms: () => {
      dispatch(getForms())
    },
    deleteForm: (id) => {
      dispatch(deleteForm(id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forms)
